import { ClickAwayListener } from '@material-ui/core'
import {
  Button,
  Flyout,
  Inline,
  Tag,
  Tooltip,
  TooltipBody,
  TypeRamp,
  useFlyout,
  useTooltip
} from '@smartsheet/lodestar-core'
import {
  AlertWarningIcon,
  EditIcon,
  KebabIcon
} from '@smartsheet/lodestar-icons'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { deleteMapping } from '../common/SmartfillApi'
import { DocuSignUpsellWrapper } from '../common/UpsellWrapper'
import { selectFeatureEnabledMap, selectUser } from '../common/store/app'
import { selectDocusignAuth } from '../common/store/docusign'
import { setError } from '../common/store/error'
import {
  deleteMappingList,
  selectCurrentMapping,
  setCurrentMapping
} from '../common/store/mapping'
import useConfirmationModal from '../common/useConfirmationModal'
import { isDocuSignMapping } from '../common/util/mapping'
import { userCanEditMapping } from '../common/util/permissions'
import GenerateControl from './GenerateControl'
import { MappingIconDocuSign, MappingIconPDF } from './MappingTypeIcons'
import RowTitle from './RowTitle.jsx'

import './MappingRow.css'

const MappingRow = ({
  mapping,
  sheet,
  setIsEditorOpen,
  searchText,
  setModalMapping
}) => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const { tooltipProps, targetProps: tooltipTargetProps } = useTooltip()

  const user = useSelector(selectUser)
  const featureEnabledMap = useSelector(selectFeatureEnabledMap)
  const currentMapping = useSelector(selectCurrentMapping)
  const docusignAuth = useSelector(selectDocusignAuth)

  const isAuthedAsCreator =
    docusignAuth?.account.accountIdGuid === mapping.docusign?.accountId

  // Have moment use the same locale as i18n
  moment.locale(i18n.language)
  const lastModifiedString = moment(mapping.lastModified).fromNow()
  // TODO: Find out supported locals, import them/setup date-fns correctly and then dump moment.js.
  // const lastModifiedString = formatDistanceToNow(mapping.lastModified)

  const [ConfirmationModal, openModal] = useConfirmationModal()

  const [upsellOpen, setUpsellOpen] = useState(false)
  const [isKebabMenuOpen, setIsKebabMenuOpen] = useState(false)
  const [hovering, setHovering] = useState(false)

  const handleEditMapping = () => {
    dispatch(setCurrentMapping(mapping))
    setIsEditorOpen(true)
  }

  const handleDeleteMapping = () =>
    deleteMapping(mapping)
      .then(() => dispatch(deleteMappingList(mapping.id)))
      .catch((error) => dispatch(setError(error)))

  const { targetProps: flyoutTargetProps, flyoutProps } = useFlyout({
    isOpen: isKebabMenuOpen,
    onCloseRequested: () => setIsKebabMenuOpen(false)
  })

  let mappingTypeIcon = <MappingIconPDF />
  if (isDocuSignMapping(mapping)) mappingTypeIcon = <MappingIconDocuSign />

  // Alternative text to use for indicating what type of mapping this is
  const typeAltText = t('mappingRow.type', {
    context: isDocuSignMapping(mapping) ? 'docusign' : 'native'
  })

  const visible = { opacity: '100%' }
  const hidden = { opacity: '0%' }

  // Close the kebab menu when the user hovers away
  if (hovering === false && isKebabMenuOpen) setIsKebabMenuOpen(false)

  const licensedToDocuSign = featureEnabledMap.DocuSign
  const isLoading = !!currentMapping && mapping.id === currentMapping.id

  return (
    <>
      <ConfirmationModal />
      <div
        className={!hovering ? 'mapping-row' : 'mapping-row hovering'}
        onMouseOver={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        onFocus={() => setHovering(true)}
        role='row'
      >
        <div role='cell'>
          <div
            className='mapping-icon-container'
            role='img'
            aria-label={typeAltText}
          >
            {mappingTypeIcon}
          </div>
        </div>
        <div role='rowheader' className='mapping-name-container'>
          <RowTitle title={mapping.name} searchText={searchText} />
          <Inline space='xSmall'>
            {isDocuSignMapping(mapping) && mapping.docusign.userName
              ? (
                <div>
                  {t('docusignAuth.mappingRow.createdBy', {
                    name: mapping.docusign.userName
                  })}
                </div>
                )
              : (
                <></>
                )}
            {mapping.lastModified
              ? (
                <div className='modified-label'>
                  {t('mappingRow.modified')} {lastModifiedString}
                </div>
                )
              : (
                <></>
                )}
            {isDocuSignMapping(mapping) && !isAuthedAsCreator
              ? (
                <div>
                  <div
                    {...tooltipTargetProps}
                    onClick={() => setModalMapping(mapping)}
                    tabIndex='0'
                  >
                    <Tag className='mapping-warning-tag' color='warning'>
                      <Inline space='xxSmall'>
                        <AlertWarningIcon size='xSmall' />
                        <TypeRamp variant='bodyStrong'>
                          {t('docusignAuth.mappingRow.logInTag')}
                        </TypeRamp>
                      </Inline>
                    </Tag>
                  </div>
                  <Tooltip {...tooltipProps} placement='top'>
                    <TooltipBody>
                      {t('docusignAuth.mappingRow.logInTagTooltip')}
                    </TooltipBody>
                  </Tooltip>
                </div>
                )
              : (
                <></>
                )}
          </Inline>
        </div>
        <div role='cell'>
          <Button
            className='edit-button'
            style={hovering ? visible : hidden}
            data-testid='edit-mapping-button'
            aria-label={t('mappingRow.edit_aria')}
            data-dd-action-name='smar:mapping.row.edit.btn'
            onClick={
              !licensedToDocuSign && isDocuSignMapping(mapping)
                ? () => setUpsellOpen(true)
                : () => handleEditMapping()
            }
            isLoading={isLoading}
            isDisabled={
              !userCanEditMapping(sheet, mapping, user) ||
              !!currentMapping ||
              (isDocuSignMapping(mapping) && !isAuthedAsCreator)
            }
            appearance='secondary'
            iconBefore={<EditIcon t={t('mappingRow.edit_aria')} />}
          />
        </div>
        <div role='cell'>
          <div
            className='generate-button'
            style={hovering ? visible : hidden}
            data-dd-action-name='smar:mapping.row.generate.btn'
            onClick={
              !licensedToDocuSign && isDocuSignMapping(mapping)
                ? () => setUpsellOpen(true)
                : null
            }
          >
            <GenerateControl
              mapping={mapping}
              openModal={openModal}
              isAuthedAsCreator={isAuthedAsCreator}
            />
          </div>
          {!licensedToDocuSign && isDocuSignMapping(mapping) && (
            <div className='upsell-container' style={hovering ? visible : hidden}>
              <DocuSignUpsellWrapper
                hardOpen={upsellOpen}
                close={() => setUpsellOpen(false)}
                context='mapping-list'
              />
            </div>
          )}
        </div>
        <div role='cell'>
          <Button
            className='kebab-button'
            aria-label={t('mappingRow.settings_menu')}
            data-dd-action-name='smar:mapping.row.kebab.btn'
            appearance='borderless'
            iconAfter={<KebabIcon />}
            {...flyoutTargetProps}
            onClick={() => {
              if (!licensedToDocuSign && isDocuSignMapping(mapping)) {
                setUpsellOpen(true)
              } else setIsKebabMenuOpen(!isKebabMenuOpen)
            }}
          />
          <Flyout {...flyoutProps} placement='bottom-end'>
            <ClickAwayListener
              onClickAway={() => {
                setIsKebabMenuOpen(false)
              }}
            >
              <div className='kebab-menu'>
                <div className='kebab-menu-item'>
                  <Button
                    className='kebab-menu-button delete'
                    data-dd-action-name='smar:mapping.row.delete.btn'
                    shouldFitContainer
                    onClick={handleDeleteMapping}
                    isDisabled={
                      !userCanEditMapping(sheet, mapping, user) ||
                      !!currentMapping
                    }
                  >
                    {t('mappingRow.delete')}
                  </Button>
                </div>
              </div>
            </ClickAwayListener>
          </Flyout>
        </div>
      </div>
    </>
  )
}

MappingRow.propTypes = {
  mapping: PropTypes.object.isRequired,
  sheet: PropTypes.object.isRequired,
  setIsEditorOpen: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  setModalMapping: PropTypes.func.isRequired
}

export default MappingRow
