// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-mappings {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
}

.no-mappings .centerpiece {
  display: flex;
  flex-direction: column;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: 10em;
}

.no-mappings .centerpiece * {
  margin-left: auto;
  margin-right: auto;
}

.nice-image img {
  width: 13em;
  padding-left: 1.6em;
  margin-bottom: 2.2em;
}

.no-mappings .centerpiece .title {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #111111;
}

.no-mappings .centerpiece .subtitle {
  margin-top: 1em;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #111111;
}

.no-mappings .centerpiece Button {
  margin-top: 1em;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
}
`, "",{"version":3,"sources":["webpack://./src/app/no-mappings/NoMappings.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;;EAEtB,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,iBAAiB;EACjB,sBAAsB;EACtB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,eAAe;EACf,mBAAmB;EACnB,oBAAoB;EACpB,kBAAkB;EAClB,iBAAiB;EACjB,sBAAsB;EACtB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,eAAe;EACf,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":[".no-mappings {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  padding-top: 1rem;\n}\n\n.no-mappings .centerpiece {\n  display: flex;\n  flex-direction: column;\n\n  margin-left: auto;\n  margin-right: auto;\n  margin-bottom: auto;\n  margin-top: 10em;\n}\n\n.no-mappings .centerpiece * {\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.nice-image img {\n  width: 13em;\n  padding-left: 1.6em;\n  margin-bottom: 2.2em;\n}\n\n.no-mappings .centerpiece .title {\n  font-size: 18px;\n  font-weight: bold;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.33;\n  letter-spacing: normal;\n  text-align: center;\n  color: #111111;\n}\n\n.no-mappings .centerpiece .subtitle {\n  margin-top: 1em;\n  font-size: 13px;\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.38;\n  letter-spacing: normal;\n  text-align: center;\n  color: #111111;\n}\n\n.no-mappings .centerpiece Button {\n  margin-top: 1em;\n  font-size: 14px;\n  font-weight: bold;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.29;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
