// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drop-zone {
  display: flex;
  width: 100%;
  height: 22em;
  border: 1px dashed #c6c6c6;
  border-radius: 2px;
  background-color: #ffffff;
  text-align: center;
  cursor: pointer;
}

.drop-zone .info {
  margin: auto;
}

.drop-zone:hover {
  border: 1px dashed #005ee0;
}

.drop-zone-label {
  color: #005ee0;
}

.drop-zone-icon {
  color: #005ee0;
  margin-bottom: 0.7em;
}
`, "",{"version":3,"sources":["webpack://./src/app/common/Dropzone.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,YAAY;EACZ,0BAA0B;EAC1B,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB","sourcesContent":[".drop-zone {\n  display: flex;\n  width: 100%;\n  height: 22em;\n  border: 1px dashed #c6c6c6;\n  border-radius: 2px;\n  background-color: #ffffff;\n  text-align: center;\n  cursor: pointer;\n}\n\n.drop-zone .info {\n  margin: auto;\n}\n\n.drop-zone:hover {\n  border: 1px dashed #005ee0;\n}\n\n.drop-zone-label {\n  color: #005ee0;\n}\n\n.drop-zone-icon {\n  color: #005ee0;\n  margin-bottom: 0.7em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
