// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wizard .modal-header .step-name {
  color: #979797;
}

.wizard .modal-body .body-container {
  display: flex;
  height: 90%;
}

.wizard .primary-panel {
  width: 100%;
  margin: 0 0 0 1.714rem;
}

.wizard .step-list {
  width: 18rem;
}

.wizard .step-list ul {
  margin: 0;
  padding: 0;
  list-style: disc outside;
  font-size: 1.1em;
}

.wizard .step-list li:first-child {
  margin-top: 0.5rem;
}

.wizard .step-list li {
  margin: 1rem 0 0 2rem;
}

.wizard .step-list li::marker {
  font-size: 2.4rem;
}

.wizard .step-list li.current::marker {
  color: #3576ba;
}

.wizard .step-list li.incomplete::marker {
  color: #eeeeee;
}

.wizard .step-list li.complete::marker {
  color: #32b1ad;
}

.wizard .step-list .step-name {
  color: #444444;
  position: relative;
  left: -0.6rem;
  top: -0.3rem;
}

.wizard .step-list .step-name.current {
  color: #111111;
  font-weight: bold;
}

.wizard .step-list li.current .step-name,
.wizard .step-list li.complete .step-name {
  cursor: pointer;
}

.wizard .modal-footer div:first-child {
  width: 100%;
  justify-content: space-between;
}

.wizard .modal-footer button {
  flex: none;
}
`, "",{"version":3,"sources":["webpack://./src/app/wizard/Wizard.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,WAAW;AACb;;AAEA;EACE,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,SAAS;EACT,UAAU;EACV,wBAAwB;EACxB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,aAAa;EACb,YAAY;AACd;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;;EAEE,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,8BAA8B;AAChC;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".wizard .modal-header .step-name {\n  color: #979797;\n}\n\n.wizard .modal-body .body-container {\n  display: flex;\n  height: 90%;\n}\n\n.wizard .primary-panel {\n  width: 100%;\n  margin: 0 0 0 1.714rem;\n}\n\n.wizard .step-list {\n  width: 18rem;\n}\n\n.wizard .step-list ul {\n  margin: 0;\n  padding: 0;\n  list-style: disc outside;\n  font-size: 1.1em;\n}\n\n.wizard .step-list li:first-child {\n  margin-top: 0.5rem;\n}\n\n.wizard .step-list li {\n  margin: 1rem 0 0 2rem;\n}\n\n.wizard .step-list li::marker {\n  font-size: 2.4rem;\n}\n\n.wizard .step-list li.current::marker {\n  color: #3576ba;\n}\n\n.wizard .step-list li.incomplete::marker {\n  color: #eeeeee;\n}\n\n.wizard .step-list li.complete::marker {\n  color: #32b1ad;\n}\n\n.wizard .step-list .step-name {\n  color: #444444;\n  position: relative;\n  left: -0.6rem;\n  top: -0.3rem;\n}\n\n.wizard .step-list .step-name.current {\n  color: #111111;\n  font-weight: bold;\n}\n\n.wizard .step-list li.current .step-name,\n.wizard .step-list li.complete .step-name {\n  cursor: pointer;\n}\n\n.wizard .modal-footer div:first-child {\n  width: 100%;\n  justify-content: space-between;\n}\n\n.wizard .modal-footer button {\n  flex: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
