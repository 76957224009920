// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar {
  height: 1.7rem;
  border-radius: 4px;
  background: #e4e4e4;
}

.progress-bar .filler {
  height: 100%;
  border-radius: inherit;
  transition: width 0.3s ease-in;
}

.progress-bar.success .filler {
  background: #00ba61;
}

.progress-bar.in-progress .filler {
  background: #005ee0;
}

.progress-bar.error .filler {
  background: #d0011b;
}
`, "",{"version":3,"sources":["webpack://./src/app/batch-job-status/ProgressBar.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,sBAAsB;EACtB,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".progress-bar {\n  height: 1.7rem;\n  border-radius: 4px;\n  background: #e4e4e4;\n}\n\n.progress-bar .filler {\n  height: 100%;\n  border-radius: inherit;\n  transition: width 0.3s ease-in;\n}\n\n.progress-bar.success .filler {\n  background: #00ba61;\n}\n\n.progress-bar.in-progress .filler {\n  background: #005ee0;\n}\n\n.progress-bar.error .filler {\n  background: #d0011b;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
