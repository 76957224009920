import { RadioGroup, ScrollArea, TypeRamp } from '@smartsheet/lodestar-core'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import AlertBar from '../../common/AlertBar'
import Spinner from '../../common/Spinner'
import { selectSmartsheetObject } from '../../common/store/app'
import {
  fetchDocusignTemplateList,
  selectDocusignTemplateList
} from '../../common/store/docusign'
import {
  fetchDocusignTemplateMetaList,
  selectCurrentMapping,
  updateCurrentMapping
} from '../../common/store/mapping'
import { HelpArticleLink } from '../../common/util/docusign'
import { getErrorDetails, parseServerError } from '../../common/util/errors'

import './TemplateSelector.css'

const docusignTemplateHelpLink =
  'https://support.docusign.com/en/guides/ndse-user-guide-create-templates'

const TemplateSelector = ({ isInitialized, setComplete }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const smartsheetObject = useSelector(selectSmartsheetObject)
  const docusignTemplateList = useSelector(selectDocusignTemplateList)
  const currentMapping = useSelector(selectCurrentMapping)

  const [selected, setSelected] = useState(null)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (docusignTemplateList) return

    setIsLoading(true)
    dispatch(fetchDocusignTemplateList(smartsheetObject))
      .unwrap()
      .catch((error) => setError(parseServerError(error)))
      .finally(() => setIsLoading(false))
  }, [docusignTemplateList, smartsheetObject])

  const options =
    docusignTemplateList?.map((template, index) => ({
      name: 'template',
      value: index,
      label: template.name || '[Untitled]'
    })) || []

  useEffect(() => {
    const currentSelection = options.find(
      (opt) =>
        docusignTemplateList[opt.value].templateId ===
        currentMapping.docusign.templateId
    )

    if (currentSelection) {
      setSelected(currentSelection)
    }
  }, [docusignTemplateList])

  const select = (selection) => {
    setSelected(selection)
    setTemplate(docusignTemplateList[selection.value])
  }

  const setTemplate = (template) => {
    if (currentMapping.docusign.templateId !== template.templateId) {
      setComplete(false)
      setIsLoading(true)

      dispatch(
        updateCurrentMapping({
          docusign: {
            ...currentMapping.docusign,
            templateId: template.templateId || '',
            recipients: {}
          },
          name: template.name || ''
        })
      )
      dispatch(fetchDocusignTemplateMetaList())
        .unwrap()
        .then(() => {
          setComplete(true)
        })
        .catch((error) => setError(error))
        .finally(() => setIsLoading(false))
    }
  }

  const errorDetails = error ? getErrorDetails(t, error) : {}

  return (
    <div className='template-selector'>
      <div className='copy-container'>
        <TypeRamp variant='headlineMedium' renderAs='h2'>
          {t('wizard.screens.docusign.templateSelector.header')}
        </TypeRamp>
        <p>{t('wizard.screens.docusign.templateSelector.subheader')}</p>
      </div>
      <div className='list-container'>
        {isLoading && <Spinner />}
        {error && (
          <AlertBar>
            <div data-testid='template-selector-error-msg'>
              <div>{errorDetails.msg}</div>
              <a
                href={HelpArticleLink}
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('errorModal.learnMore', 'Learn more')}
              </a>
            </div>
          </AlertBar>
        )}
        {!error && docusignTemplateList?.length > 0 && (
          <ScrollArea
            gutter='xSmall'
            lockPageScroll
            data-testid='ds-template-list'
            data-dd-action-name='smar:wizard.docusign.template.selector'
          >
            <RadioGroup
              name='selectedTemplate'
              selected={selected}
              options={options}
              isDisabled={isLoading || isInitialized}
              onChange={select}
            />
          </ScrollArea>
        )}
        {!isLoading && !error && !docusignTemplateList?.length && (
          <div className='no-templates' data-testid='no-ds-templates'>
            <p>{t('wizard.screens.docusign.templateSelector.noTemplates')}</p>
            <a
              href={docusignTemplateHelpLink}
              target='_blank'
              rel='noopener noreferrer'
            >
              {t('wizard.screens.docusign.templateSelector.templateHelpLink')}
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

TemplateSelector.propTypes = {
  isInitialized: PropTypes.bool.isRequired,
  setComplete: PropTypes.func.isRequired
}

export default TemplateSelector
