import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import InfoToolTip from '../common/InfoTooltip'
import { ExitIcon } from '../common/icon/BasicIcons'
import { emitEvent } from '../common/util/events'
import { isInside } from '../common/util/format'

import './OutputNameField.css'

// this component is slated for a future re-write and has limited test coverage; keep changes minimal
const OutputNameField = ({ mapping, hidden, setOutputNameColumn }) => {
  const { t } = useTranslation()

  const [isDragging, setIsDragging] = useState(false)
  const [isDragHover, setIsDragHover] = useState(false)
  const [isMouseHover, setIsMouseHover] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const isColCompatible = useRef()
  const fieldRef = useRef()

  useEffect(() => {
    document.addEventListener('columndrag', dragListener)
    document.addEventListener('columndrop', dropListener)

    setInputValue(mapping?.outputName?.value || '')

    return () => {
      document.removeEventListener('columndrag', dragListener)
      document.removeEventListener('columndrop', dropListener)
    }
  }, [])

  useEffect(() => {
    setIsDragHover(false)

    if (mapping?.outputName?.type === 'column') {
      setInputValue(mapping?.outputName?.value)
    }
  }, [mapping])

  const getClassName = () => {
    if (isDragHover) { return `drag-hover ${isColCompatible.current ? 'valid' : 'invalid'}` }
    if (isDragging) { return `dragging ${isColCompatible.current ? 'valid' : 'invalid'}` }

    if (mapping?.outputName) {
      const pairing = mapping.outputName
      if (pairing && pairing.type === 'column') { return `mapped ${pairing.type}${isMouseHover ? ' hover' : ''}` }
    }

    return 'default'
  }

  const deletePairing = () => {
    setInputValue('')
    const blankOutputName = { type: '' }
    setOutputNameColumn(blankOutputName)
  }

  // Called when user types in outputName field
  const handleInputChange = (event) => {
    // Map the field with constant
    setInputValue(event.target.value)
    setOutputNameColumn({
      type: 'constant',
      value: event.target.value
    })
  }

  // returns true if the column can be used for the outputName
  const checkCompatibility = (column) => {
    const columnType = column ? column.type : ''
    switch (columnType) {
      case 'TEXT_NUMBER':
      case 'DATE':
      case 'DATETIME':
      case 'ABSTRACT_DATETIME':
      case 'DURATION':
      case 'PICKLIST':
      case 'CONTACT_LIST':
        return true
      default:
        return false
    }
  }

  const dragListener = (event) => {
    // When user starts dragging a column card
    // calculate whether this field is compatible with it
    isColCompatible.current = checkCompatibility(event.detail.column)

    setIsDragging(true)
    setIsDragHover((previous) => {
      if (
        isInside(
          event.detail.pos.positionX,
          event.detail.pos.positionY,
          fieldRef,
          hidden
        )
      ) {
        // Pass something identifiable via emitEvent
        emitEvent('fieldhover', {
          fieldType: 'outputNameMapping',
          compatible: isColCompatible.current
        })
        return true
      } else if (previous) {
        emitEvent('fieldhover', {})
        return false
      } else {
        return previous
      }
    })
  }

  const dropListener = () => {
    isColCompatible.current = false
    setIsDragging(false)
    setIsDragHover(false)
  }

  return (
    <div className='pdf-output-name'>
      <div className='output-name-label-container'>
        <label id='outputNameLabel' className='output-name-label'>
          {t('outputFilename.label')}
        </label>
        <InfoToolTip tooltipLocalization='outputFilename.information' />
      </div>

      <div
        className={'output-name ' + getClassName()}
        ref={fieldRef}
        onMouseEnter={() => setIsMouseHover(true)}
        onMouseLeave={() => setIsMouseHover(false)}
      >
        <input
          type='text'
          placeholder={mapping?.name ? mapping.name : t('outputFilename.label')}
          value={inputValue}
          onChange={handleInputChange}
          aria-labelledby='outputNameLabel'
          style={{
            fontSize: '18px',
            width: '100%',
            height: '100%',
            border: 'none'
          }}
        />
        {isMouseHover && inputValue
          ? (
            <div className={`unpair-button ${mapping?.outputName?.type}`}>
              <button
                onClick={deletePairing}
                data-dd-action-name='smar:form.field.delete.btn'
              >
                <ExitIcon t={t('formField.exitIcon')} />
              </button>
            </div>
            )
          : (
              ''
            )}
      </div>
    </div>
  )
}

export default OutputNameField
