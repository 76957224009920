// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recipient-type {
  color: #727272;
}

.right-arrow-container {
  width: 2rem;
  height: 2rem;
  align-self: center;
  border-radius: 0.4rem;
  border: 0.1rem solid #ebebeb;
  display: flex;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/app/wizard/role-assigner/RecipientRow.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,qBAAqB;EACrB,4BAA4B;EAC5B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".recipient-type {\n  color: #727272;\n}\n\n.right-arrow-container {\n  width: 2rem;\n  height: 2rem;\n  align-self: center;\n  border-radius: 0.4rem;\n  border: 0.1rem solid #ebebeb;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
