import { TypeRamp } from '@smartsheet/lodestar-core'
import pick from 'lodash/pick'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import AlertBar from '../../common/AlertBar'
import InfoTooltip from '../../common/InfoTooltip'
import { selectSheet } from '../../common/store/app'
import { selectTemplateById } from '../../common/store/docusign'
import {
  selectCurrentMapping,
  updateCurrentMapping
} from '../../common/store/mapping'
import { flattenRecipientsList } from '../../common/util/docusign'
import { getErrorDetails } from '../../common/util/errors'
import RecipientRow from './RecipientRow'
import {
  areAllRecipientsFilled,
  getColumnOptions,
  isStaticRecipient
} from './helpers'

import './RoleAssigner.css'

const RoleAssigner = ({ setComplete, isInitialized }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const sheet = useSelector(selectSheet)
  const currentMapping = useSelector(selectCurrentMapping)
  const template = useSelector(
    selectTemplateById(currentMapping.docusign.templateId)
  )

  const docusignRecipientList = currentMapping.docusign.recipients
  const templateRecipientList = template?.recipients

  const flattenedRecipientList = useMemo(
    () => flattenRecipientsList(templateRecipientList),
    [templateRecipientList]
  )

  useEffect(() => {
    if (isInitialized || !templateRecipientList) return

    const recipientPairings = Object.fromEntries(
      flattenedRecipientList
        .filter(isStaticRecipient)
        .map((recipient) => [
          recipient.recipientId,
          { useDocuSignValues: true }
        ])
    )

    setRecipientPairing(recipientPairings)
  }, [])

  useEffect(() => {
    if (template) {
      setComplete(
        areAllRecipientsFilled(flattenedRecipientList, docusignRecipientList)
      )
    }
  }, [template, flattenedRecipientList, docusignRecipientList])

  const setRecipientPairing = (pairing) => {
    const templateIds = flattenedRecipientList.map(
      (recipient) => recipient.recipientId
    )

    const updatedRecipients = pick(
      {
        ...docusignRecipientList,
        ...pairing
      },
      templateIds
    )

    dispatch(
      updateCurrentMapping({
        docusign: {
          ...currentMapping.docusign,
          recipients: updatedRecipients
        }
      })
    )
  }

  return (
    <div className='role-assigner'>
      <div className='copy-container'>
        <TypeRamp variant='headlineMedium' renderAs='h2'>
          {t('wizard.screens.docusign.roleAssigner.header')}
        </TypeRamp>
        <p>{t('wizard.screens.docusign.roleAssigner.subheader')}</p>
      </div>
      <div className='grid-container'>
        {template && (
          <div className='grid-body'>
            <div className='grid-header'>
              <div>
                {t('wizard.screens.docusign.roleAssigner.roleInfoHeader')}
              </div>
              <div />
              <div>
                {t('wizard.screens.docusign.roleAssigner.columnSelectorHeader')}
                <InfoTooltip tooltipLocalization='wizard.screens.docusign.roleAssigner.tooltip' />
              </div>
              <div />
            </div>
            <div className='grid-content'>
              {flattenRecipientsList(templateRecipientList).map(
                (templateRecipient) =>
                  docusignRecipientList && (
                    <RecipientRow
                      key={templateRecipient.recipientId}
                      recipient={templateRecipient}
                      pairing={
                        docusignRecipientList[templateRecipient.recipientId]
                      }
                      options={getColumnOptions(sheet)}
                      update={setRecipientPairing}
                    />
                  )
              )}
            </div>
          </div>
        )}
        {!template && <AlertBar>{getErrorDetails(t).msg}</AlertBar>}
      </div>
    </div>
  )
}

RoleAssigner.propTypes = {
  isInitialized: PropTypes.bool.isRequired,
  setComplete: PropTypes.func.isRequired
}

export default RoleAssigner
