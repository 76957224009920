// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sort-and-filter-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
}

.sort-and-filter-bar .sort {
  width: 210px;
  margin-right: 12px;
}

.sort-and-filter-bar .filter {
  width: 210px;
  margin-right: 12px;
}

.sort-and-filter-bar .search {
  width: 250px;
  height: 40px;
  margin-right: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/app/mappings-list/SortAndFilterBar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".sort-and-filter-bar {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  align-items: flex-start;\n}\n\n.sort-and-filter-bar .sort {\n  width: 210px;\n  margin-right: 12px;\n}\n\n.sort-and-filter-bar .filter {\n  width: 210px;\n  margin-right: 12px;\n}\n\n.sort-and-filter-bar .search {\n  width: 250px;\n  height: 40px;\n  margin-right: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
