import { Button, TypeRamp } from '@smartsheet/lodestar-core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import PageHeader from '../common/PageHeader'

import './Login.css'

const Login = () => {
  const { t } = useTranslation()

  const handleClick = (event) => {
    event.preventDefault()

    // Extract and sanitize the query parameters
    const urlParams = new URLSearchParams(window.location.search)
    const allowedParams = ['objectId', 'rowIds']
    const sanitizedParams = new URLSearchParams()

    // Filter only the allowed parameters
    for (const [key, value] of urlParams.entries()) {
      if (allowedParams.includes(key)) {
        sanitizedParams.append(key, value)
      }
    }

    // Construct the target URL with the sanitized query string
    const targetUrl = `${process.env.BASE_API_URL}/api/auth/login?${sanitizedParams.toString()}`

    window.location.assign(targetUrl)
  }

  return (
    <div>
      <PageHeader />
      <div className='login'>
        <div className='login--spacer' />
        <div className='login--content'>
          <TypeRamp variant='headerLarge'>{t('login.description')}</TypeRamp>
          <Button onClick={handleClick} data-dd-action-name='smar:login.btn'>
            {t('login.button')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Login
