// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.email-preview {
  padding-bottom: 1em;
}

.role-msg-previews .email-preview {
  border-top: 2px ridge;
  padding-top: 1em;
}

.email-preview .email-body {
  font-weight: normal;
}

.email-previewer .spinner {
  margin: auto;
}
`, "",{"version":3,"sources":["webpack://./src/app/editor/EmailPreviewer.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".email-preview {\n  padding-bottom: 1em;\n}\n\n.role-msg-previews .email-preview {\n  border-top: 2px ridge;\n  padding-top: 1em;\n}\n\n.email-preview .email-body {\n  font-weight: normal;\n}\n\n.email-previewer .spinner {\n  margin: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
