import { TextInput, Toggle, TypeRamp } from '@smartsheet/lodestar-core'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ExclaimIcon } from '../../common/icon/BasicIcons'
import { selectSheet } from '../../common/store/app'
import {
  selectCurrentMapping,
  updateCurrentMapping
} from '../../common/store/mapping'

import './StatusColumnConfigurator.css'

const StatusColumnConfigurator = ({ setComplete }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const sheet = useSelector(selectSheet)
  const currentMapping = useSelector(selectCurrentMapping)

  const sheetStatusColumn = sheet.columns.find(
    (col) => col.id === currentMapping.docusign.statusColumnId
  )

  const tempStatusColumn = currentMapping.temp?.statusColumnName

  const isInitialized = useRef(!!sheetStatusColumn)
  const [isTrackingEnabled, setIsTrackingEnabled] = useState(
    !!(sheetStatusColumn || tempStatusColumn)
  )
  const [columnName, setColumnName] = useState(
    sheetStatusColumn?.title || tempStatusColumn || ''
  )

  useEffect(() => {
    if (isInitialized.current) return
    if (currentMapping.temp?.statusColumnName === columnName) return

    if (!isTrackingEnabled) {
      dispatch(
        updateCurrentMapping({
          temp: {
            ...currentMapping.temp,
            statusColumnName: null
          }
        })
      )
      setComplete(true)
    } else if (isColumnNameValid) {
      dispatch(
        updateCurrentMapping({
          temp: {
            ...currentMapping.temp,
            statusColumnName: columnName
          }
        })
      )
      setComplete(true)
    } else {
      dispatch(
        updateCurrentMapping({
          temp: {
            ...currentMapping.temp,
            statusColumnName: null
          }
        })
      )
      setComplete(false)
    }
  }, [isTrackingEnabled, columnName])

  const toggleTracking = () => setIsTrackingEnabled((prev) => !prev)

  const isColumnNameUnique = () => {
    if (!sheet) return true
    const existingColumnWithName = sheet.columns.find(
      (col) => col.title.toLowerCase() === columnName.toLowerCase()
    )

    // If the existing column is the configured one, then should be treated as unique
    if (existingColumnWithName?.id === sheetStatusColumn?.id) return true
    return !existingColumnWithName
  }

  const isColumnNameEmpty = columnName === ''

  const isColumnNameValid =
    columnName &&
    isColumnNameUnique() &&
    /\S/.test(columnName) && // contains a non whitespace character
    !columnName.startsWith(' ') &&
    !columnName.endsWith(' ')

  const getErrorString = () => {
    if (!isColumnNameUnique()) {
      return t(
        'wizard.screens.docusign.statusColumnConfig.validationErrors.notUnique'
      )
    }
    return t(
      'wizard.screens.docusign.statusColumnConfig.validationErrors.badSpaces'
    )
  }

  const allowChanges = !isInitialized.current
  const showError = !isColumnNameEmpty && !isColumnNameValid
  const showTextBox = isTrackingEnabled

  return (
    <div className='status-column-configurator'>
      <TypeRamp variant='headlineMedium' renderAs='h2'>
        {t('wizard.screens.docusign.statusColumnConfig.header')}
      </TypeRamp>
      <p>{t('wizard.screens.docusign.statusColumnConfig.subheader')}</p>
      <div>
        <Toggle
          className='toggle-status-tracking'
          isChecked={isTrackingEnabled}
          onClick={toggleTracking}
          label={t('wizard.screens.docusign.statusColumnConfig.toggleLabel')}
          isDisabled={!allowChanges}
        />
        {showTextBox && (
          <>
            <TextInput
              value={columnName}
              type='text'
              data-dd-action-name={
                'smar:wizard.docusign.status.column.toggle.' +
                (showTextBox ? 'enable' : 'disable') +
                '.btn'
              }
              className='column-name-input'
              isDisabled={!allowChanges}
              onChange={setColumnName}
              validationState={showError ? 'error' : undefined}
              placeholder={t(
                'wizard.screens.docusign.statusColumnConfig.inputPlaceholder'
              )}
            />
            {showError && (
              <div
                className='invalid-name-label'
                data-testid='invalid-name-label'
              >
                <ExclaimIcon />
                {getErrorString()}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

StatusColumnConfigurator.propTypes = {
  isInitialized: PropTypes.bool.isRequired,
  setComplete: PropTypes.func.isRequired
}

export default StatusColumnConfigurator
