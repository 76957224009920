import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Mapper from '../Mapper'
import Callback from '../auth/Callback'
import DocuSignCallback from '../auth/DocuSignCallback'
import Login from '../auth/Login'
import NotFound from './NotFound'
import { APP_DOMAIN } from './constant'

const Router = () => {
  return (
    <Switch>
      <Route exact path={`${APP_DOMAIN}/`}>
        <Mapper />
      </Route>

      <Route exact path={`${APP_DOMAIN}/auth/callback`}>
        <Callback />
      </Route>

      <Route exact path={`${APP_DOMAIN}/auth/callback/docusign`}>
        <DocuSignCallback />
      </Route>

      <Route exact path={`${APP_DOMAIN}/login`}>
        <Login />
      </Route>
      <Route component={NotFound} />
    </Switch>
  )
}

export default Router
