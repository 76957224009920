// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.doc-handler {
  height: 100%;
}

.top-controls {
  display: flex;
  margin-right: 12px;
}

.doc-handler .top-controls .generate-control {
  margin-right: 10px;
}

.editor-controls {
  display: flex;
  margin: auto;
  flex-grow: 3;
  justify-content: flex-end;
  padding-top: 1.2em;
}

.doc-handler .editor-control {
  margin-left: 5px;
  margin-right: 0;
}

.doc-handler .page-number {
  width: 30%;
  background-color: #ffffff;
  padding-top: 0.6em;
}

.doc-handler .bottom-panel {
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 2em;
}

.doc-handler .pdf-document {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/app/editor/DocHandler.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,gBAAgB;AAClB","sourcesContent":[".doc-handler {\n  height: 100%;\n}\n\n.top-controls {\n  display: flex;\n  margin-right: 12px;\n}\n\n.doc-handler .top-controls .generate-control {\n  margin-right: 10px;\n}\n\n.editor-controls {\n  display: flex;\n  margin: auto;\n  flex-grow: 3;\n  justify-content: flex-end;\n  padding-top: 1.2em;\n}\n\n.doc-handler .editor-control {\n  margin-left: 5px;\n  margin-right: 0;\n}\n\n.doc-handler .page-number {\n  width: 30%;\n  background-color: #ffffff;\n  padding-top: 0.6em;\n}\n\n.doc-handler .bottom-panel {\n  padding-left: 2em;\n  padding-right: 2em;\n  padding-bottom: 2em;\n}\n\n.doc-handler .pdf-document {\n  margin-left: auto;\n  margin-right: auto;\n  margin-top: 0;\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
