// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.batch-job-status-module .module-button {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  right: 2em;
}

.batch-job-status-module .list-container {
  position: fixed;
  z-index: 1000;
  right: 1.9rem;
  bottom: 2.53rem;
  max-height: 27rem;
  overflow-y: auto;
  background: #ffffff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
  padding: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/batch-job-status/BatchJobStatusModule.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,aAAa;EACb,aAAa;EACb,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;EACnB,wCAAwC;EACxC,2CAA2C;EAC3C,aAAa;AACf","sourcesContent":[".batch-job-status-module .module-button {\n  position: fixed;\n  z-index: 1000;\n  bottom: 0;\n  right: 2em;\n}\n\n.batch-job-status-module .list-container {\n  position: fixed;\n  z-index: 1000;\n  right: 1.9rem;\n  bottom: 2.53rem;\n  max-height: 27rem;\n  overflow-y: auto;\n  background: #ffffff;\n  border: 1px solid rgba(34, 36, 38, 0.15);\n  box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);\n  padding: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
