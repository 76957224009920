import { Button, Spacer } from '@smartsheet/lodestar-core'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { useDispatch, useSelector } from 'react-redux'

import { getDocuSignPDF, getPDF } from '../common/SmartfillApi'
import Spinner from '../common/Spinner'
import { setError } from '../common/store/error'
import {
  clearCurrentMapping,
  fetchDocusignTemplateMetaList,
  selectCurrentMapping
} from '../common/store/mapping'
import { getFieldsFromDocuments } from '../common/util/docusign'
import { MAPPING_TYPES, getMappingType } from '../common/util/mapping'
import FormField from './FormField'

import './DocumentViewer.css'

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.BASE_CLOUDFRONT_URL}/document-builder-microfrontend/latest/pdf.worker.min.js`

const fetchPdfData = async (mapping) => {
  const isNative = !!mapping.document?.id
  const isDocusign = !!mapping.docusign?.templateId

  if (!isNative && !isDocusign) {
    return Promise.reject(
      new Error('The currentMapping does contain a document id')
    )
  }

  let blob
  try {
    blob = isNative
      ? await getPDF(mapping.document.id)
      : await getDocuSignPDF(
        mapping.docusign.templateId,
        mapping.smartsheetObject
      )
  } catch (error) {
    await unpackBlobError(error)
    return Promise.reject(error)
  }

  const file = new Blob([blob], { type: 'application/pdf' })
  file.id = isNative ? mapping.document.id : mapping.docusign.templateId

  return Promise.resolve(file)
}

const unpackBlobError = async (error) => {
  // required because getPDF and getDocuSignPDF return error data as blobs
  if (error.response.data instanceof Blob) {
    const json = await error.response.data.text()
    error.response.data = JSON.parse(json)
  }
}

/**
 * DocumentViewer handles display of the PDF document and the overlaying of the
 * FormField objects which are used to create and delete mappings
 */

const DocumentViewer = ({ setFieldPairing }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const currentMapping = useSelector(selectCurrentMapping)

  const [pdf, setPdf] = useState(null)

  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [currentPage, setCurrentPage] = useState(null)
  const [numPages, setNumPages] = useState(1)

  useEffect(() => {
    fetchPdfData(currentMapping)
      .then((response) => {
        setPdf(response)
      })
      .then(() => {
        if (
          getMappingType(currentMapping) === MAPPING_TYPES.docusign &&
          !currentMapping?.docusign?.templateMetaList
        ) {
          return dispatch(fetchDocusignTemplateMetaList()).unwrap()
        }
      })
      .catch((error) => {
        dispatch(setError(error))
        dispatch(clearCurrentMapping())
      })
  }, [])

  const parseFields = () => {
    const mappingType = getMappingType(currentMapping)

    if (mappingType === MAPPING_TYPES.native) {
      return currentMapping.document.fields
    }

    if (mappingType === MAPPING_TYPES.docusign) {
      return getFieldsFromDocuments(currentMapping?.docusign?.templateMetaList)
    }

    return []
  }

  const onDocumentLoadSuccess = (document) => {
    if (document.numPages !== numPages) setNumPages(document.numPages)
  }

  const onPageLoadSuccess = (page) => {
    if (currentPage !== page) setCurrentPage(page)
  }

  return (
    <div className='document-viewer'>
      <Document
        file={pdf}
        noData={<Spinner />}
        loading={<Spinner />}
        onLoadSuccess={onDocumentLoadSuccess}
        className='pdf-document'
        options={{
          // Support latin characters (in our case Asian languages) in react-pdf.
          cMapUrl: 'cmaps/',
          cMapPacked: true,
          // Support PDF < v1.5 feature "standard fonts" in react-pdf.
          standardFontDataUrl: 'standard_fonts/',
          // Show errors only in console.
          verbosity: 0
        }}
      >
        <Page
          pageNumber={currentPageNumber}
          renderAnnotationLayer
          onLoadSuccess={onPageLoadSuccess}
        >
          {currentPage === null
            ? null
            : parseFields().map((field, index) => {
              if (currentPageNumber !== field.pageNumber) return null

              return (
                <FormField
                  field={field}
                  page={currentPage}
                  key={index}
                  idLabel={index}
                  mapping={currentMapping}
                  setFieldPairing={setFieldPairing}
                />
              )
            })}
        </Page>
      </Document>
      {numPages > 1 && (
        <Spacer className='page-controls' space='small'>
          <Button
            data-dd-action-name='smar:doc.viewer.prevpage.btn'
            onClick={() => setCurrentPageNumber((prev) => prev - 1)}
            isDisabled={currentPageNumber === 1}
            appearance='secondary'
          >
            {t('documentViewer.prevPage')}
          </Button>
          <Button
            data-dd-action-name='smar:doc.viewer.nextpage.btn'
            onClick={() => setCurrentPageNumber((prev) => prev + 1)}
            isDisabled={currentPageNumber === numPages}
            appearance='secondary'
          >
            {t('documentViewer.nextPage')}
          </Button>
        </Spacer>
      )}
    </div>
  )
}

DocumentViewer.propTypes = {
  setFieldPairing: PropTypes.func.isRequired
}

export default DocumentViewer
