import React, { FunctionComponent, ReactElement } from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact, { ReactAppOrParcel } from 'single-spa-react'
import AppWrapper from './app'

const createParcelConfig = (
  FrontEnd: React.FunctionComponent<any>
): ReactAppOrParcel<any> =>
  singleSpaReact({
    React,
    ReactDOM,
    loadRootComponent: async (props: any): Promise<FunctionComponent> => {
      return () => <FrontEnd {...props} />
    },
    errorBoundary(err, info, props): ReactElement {
      console.error(err)
      // Customize the root error boundary for your microfrontend here.
      return <></>
    }
  })

const parcelConfig = createParcelConfig(AppWrapper)

export const { bootstrap, mount, unmount } = parcelConfig
export default parcelConfig
