import { Button } from '@smartsheet/lodestar-core'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import BatchJobStatusItem from '../batch-job-status/BatchJobStatusItem'
import {
  refreshBatchJobStatus,
  selectBatchJobStatusIdList,
  selectIncompleteBatchJobStatusIdList
} from '../common/store/batchJobStatus'
import { setError } from '../common/store/error'
import useInterval from './useInterval'

import './BatchJobStatusModule.css'

const BatchJobStatusModule = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isCheckingBatchJobStatus, setIsCheckingBatchJobStatus] = useState(true)

  const listContainerRef = useRef(null)
  const listLastCountRef = useRef(0)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const batchJobStatusIdList = useSelector(selectBatchJobStatusIdList)
  const incompleteBatchJobStatusIdList = useSelector(
    selectIncompleteBatchJobStatusIdList
  )
  useInterval(
    () => checkBatchJobStatus(),
    isCheckingBatchJobStatus ? 3000 : null
  )

  useEffect(() => {
    setIsOpen(true)
  }, [])

  useEffect(() => {
    // If batch jobs exist item drawer should be visible,
    // check batch job statuses on an interval till all finished.
    if (batchJobStatusIdList.length) {
      setIsCheckingBatchJobStatus(true)
    } else {
      setIsCheckingBatchJobStatus(false)
    }
  }, [batchJobStatusIdList])

  useEffect(() => {
    const listCurrentCount = batchJobStatusIdList.length
    // Scroll down if new item was added to list.
    if (
      listContainerRef.current &&
      listCurrentCount > listLastCountRef.current
    ) {
      listContainerRef.current.scrollTo({
        left: 0,
        top: listContainerRef.current.scrollHeight,
        behavior: 'smooth'
      })
    }

    // Keep track of number of items to scroll down for new ones.
    if (listCurrentCount !== listLastCountRef.current) {
      listLastCountRef.current = listCurrentCount
    }
  }, [listContainerRef.current, listLastCountRef.current, batchJobStatusIdList])

  const checkBatchJobStatus = async () => {
    let isErrors = false

    for (const batchJobStatusId of incompleteBatchJobStatusIdList) {
      try {
        await dispatch(refreshBatchJobStatus(batchJobStatusId)).unwrap()
      } catch (error) {
        dispatch(setError(error))
        isErrors = true
        break
      }
    }

    // If error thrown OR all batch job status items are finished processing turn refresh off.
    if (isErrors || !incompleteBatchJobStatusIdList.length) {
      setIsCheckingBatchJobStatus(false)
    }
  }

  return (
    <>
      {!!batchJobStatusIdList.length && (
        <div className='batch-job-status-module'>
          <Button
            className='module-button'
            appearance='primary'
            onClick={() => setIsOpen((curVal) => !curVal)}
            data-dd-action-name='smar:job.status.module.show.btn'
          >
            {t('jobStatusModule.button', 'Attachment Jobs')}
          </Button>
          {isOpen && (
            <div ref={listContainerRef} className='list-container'>
              {batchJobStatusIdList.map((jobId) => {
                return (
                  <BatchJobStatusItem key={jobId} batchJobStatusId={jobId} />
                )
              })}
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default BatchJobStatusModule
