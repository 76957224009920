// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: 'Inter', Arial, Helvetica, sans-serif, 'Segoe UI Emoji',
    'Segoe UI Symbol';
  /* This is Lodestar's standard body font size/height. */
  font-size: 13px;
  line-height: 18px;
}

@supports (font-variation-settings: normal) {
  body {
    font-family: 'Inter var', Arial, Helvetica, sans-serif, 'Segoe UI Emoji',
      'Segoe UI Symbol';
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/app/common/common.css"],"names":[],"mappings":"AAAA;EACE;qBACmB;EACnB,uDAAuD;EACvD,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE;IACE;uBACmB;EACrB;AACF;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd","sourcesContent":["body {\n  font-family: 'Inter', Arial, Helvetica, sans-serif, 'Segoe UI Emoji',\n    'Segoe UI Symbol';\n  /* This is Lodestar's standard body font size/height. */\n  font-size: 13px;\n  line-height: 18px;\n}\n\n@supports (font-variation-settings: normal) {\n  body {\n    font-family: 'Inter var', Arial, Helvetica, sans-serif, 'Segoe UI Emoji',\n      'Segoe UI Symbol';\n  }\n}\n\n.spinner-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
