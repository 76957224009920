// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-reviewer {
  height: 100%;
}

.settings-reviewer .copy-container {
  height: 10%;
}

.settings-reviewer .grid-container {
  height: 90%;
  overflow-y: auto;
  padding: 0 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/wizard/settings-reviewer/SettingsReviewer.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".settings-reviewer {\n  height: 100%;\n}\n\n.settings-reviewer .copy-container {\n  height: 10%;\n}\n\n.settings-reviewer .grid-container {\n  height: 90%;\n  overflow-y: auto;\n  padding: 0 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
