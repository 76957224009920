import {
  Button,
  Link,
  TextInput,
  Tooltip,
  TooltipBody,
  TypeRamp,
  useTooltip
} from '@smartsheet/lodestar-core'
import {
  FeedbackIcon,
  HelpIcon,
  PlusIcon,
  SyncLeftIcon
} from '@smartsheet/lodestar-icons'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import authUtil from '../auth/authUtil'
import { LogoutIcon } from './icon/BasicIcons'
import { selectSheet, selectSmartsheetObject, selectUser } from './store/app'
import { setError } from './store/error'
import {
  clearCurrentMapping,
  saveCurrentMapping,
  selectCurrentMapping,
  selectIsCurrentMappingChanged,
  setCurrentMapping,
  updateCurrentMapping
} from './store/mapping'
import { resetStore } from './store/store'
import useConfirmationModal from './useConfirmationModal'
import { msgKey } from './util/confModal'
import { getPopulatedFeedbackURL, helpURL } from './util/navigation'
import { userCanCreateMappings } from './util/permissions'

import './PageHeader.css'

const WithTooltip = ({ children, tooltipContent, ...props }) => {
  const { tooltipProps, targetProps } = useTooltip()

  return (
    <>
      <div {...props} {...targetProps}>
        {children}
      </div>
      <Tooltip {...tooltipProps} placement='bottom'>
        <TooltipBody>{tooltipContent}</TooltipBody>
      </Tooltip>
    </>
  )
}

const PageHeader = ({ setIsWizardOpen, setIsEditorOpen }) => {
  /* global location */

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const user = useSelector(selectUser)
  const sheet = useSelector(selectSheet)
  const smartsheetObject = useSelector(selectSmartsheetObject)
  const currentMapping = useSelector(selectCurrentMapping)
  const isCurrentMappingChanged = useSelector(selectIsCurrentMappingChanged)

  const [ConfirmationModal, openModal] = useConfirmationModal()

  const returnURL = sheet?.permalink || ''
  const feedbackURL = getPopulatedFeedbackURL(user)

  const returnToSmartsheet = () => {
    window.onbeforeunload = null
    location.assign(returnURL)
  }

  const handleReturnToSmartsheet = (event) => {
    event.preventDefault()
    if (!isCurrentMappingChanged) return returnToSmartsheet()
    openModal({
      message: msgKey.Unsaved,
      buttonHandlers: {
        onCancel: () => {},
        onDoNotSave: () => returnToSmartsheet(),
        onSave: () =>
          dispatch(saveCurrentMapping())
            .unwrap()
            .then(() => returnToSmartsheet())
            .catch((error) => dispatch(setError(error)))
      }
    })
  }

  const handleCreateMapping = () => {
    // Create a mapping object in a "new" state and set as the current mapping.
    dispatch(setCurrentMapping({ smartsheetObject }))
    setIsWizardOpen(true)
  }

  const handleClearMapping = () => {
    const exitFn = () => {
      dispatch(clearCurrentMapping())
      setIsEditorOpen(false)
    }

    if (!isCurrentMappingChanged) return exitFn()
    openModal({
      message: msgKey.Unsaved,
      buttonHandlers: {
        onCancel: () => {},
        onDoNotSave: exitFn,
        onSave: () =>
          dispatch(saveCurrentMapping())
            .unwrap()
            .then(exitFn)
            .catch((error) => dispatch(setError(error)))
      }
    })
  }

  const handleLogout = () => {
    dispatch(resetStore())
    authUtil.logout()
  }

  const mappingNameOnEdit = (value) => {
    dispatch(updateCurrentMapping({ name: value }))
  }

  return (
    <header className='page-header'>
      <ConfirmationModal />
      <div className='left-box'>
        {sheet && (
          <>
            <Link
              data-testid='return-link'
              data-dd-action-name='smar:header.return.to.ss.btn'
              aria-label={t('header.returnLink', {
                sheetName: sheet.name
              })}
              appearance='borderless'
              onClick={handleReturnToSmartsheet}
              href={returnURL}
              iconBefore={<SyncLeftIcon />}
            >
              {sheet.name}
            </Link>
            <span>/</span>
            {!currentMapping
              ? (
                <TypeRamp
                  className='mapping-crumb-title'
                  data-testid='manager-crumb'
                  variant='buttonLarge'
                >
                  {t('header.mappings')}
                </TypeRamp>
                )
              : (
                <Button
                  data-dd-action-name='smar:header.mappings.btn'
                  data-testid='manager-crumb'
                  appearance='borderless'
                  onClick={handleClearMapping}
                >
                  {t('header.mappings')}
                </Button>
                )}
            {!!currentMapping && (
              <>
                <span>/</span>
                {/* Text input for renaming a mapping */}
                <TextInput
                  data-testid='mapping-title-field'
                  type='text'
                  value={currentMapping.name || ''}
                  className='mapping-title-field'
                  aria-label={t('editor.ariaInstructions')}
                  onChange={mappingNameOnEdit}
                  maxLength='200'
                />
              </>
            )}
          </>
        )}
      </div>

      <div className='right-box'>
        <WithTooltip tooltipContent={<div>{t('header.help', 'Help')}</div>}>
          <Link
            target='_blank'
            href={helpURL}
            rel='noopener noreferrer'
            aria-label={t('header.help', 'Help')}
            appearance='button-borderless'
            iconBefore={<HelpIcon color='#444444' />}
          />
        </WithTooltip>

        <WithTooltip
          tooltipContent={<div>{t('header.feedback', 'Send Feedback')}</div>}
        >
          <Link
            target='_blank'
            href={feedbackURL}
            rel='noopener noreferrer'
            aria-label={t('header.feedback', 'Send Feedback')}
            appearance='button-borderless'
            iconBefore={<FeedbackIcon color='#444444' />}
          />
        </WithTooltip>

        {user && (
          <WithTooltip
            tooltipContent={<div>{t('header.logout', 'Sign Out')}</div>}
          >
            <Button
              onClick={handleLogout}
              aria-label={t('header.logout', 'Sign Out')}
              data-dd-action-name='smar:header.signout.btn'
              appearance='borderless'
              style={{ width: '40px', color: '#444444' }}
              iconBefore={<LogoutIcon />}
            />
          </WithTooltip>
        )}

        {sheet && (
          <Button
            className='button-create'
            data-testid='button-create-page-header'
            data-dd-action-name='smar:header.create.mapping.btn'
            isDisabled={!!currentMapping || !userCanCreateMappings(sheet)}
            iconBefore={<PlusIcon size='small' />}
            onClick={handleCreateMapping}
          >
            {t('header.createButton', 'Create')}
          </Button>
        )}
      </div>
    </header>
  )
}

PageHeader.propTypes = {
  setIsWizardOpen: PropTypes.func,
  setIsEditorOpen: PropTypes.func
}

export default PageHeader
