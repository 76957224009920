// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-slot {
  min-height: 2.6em;
  margin-top: 0.7em;
  margin-bottom: 0.7em;
}

.card-list {
  padding-bottom: 2em;
  max-height: 80%;
  margin-top: 0em;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 0.5em;
  margin-bottom: 2em;
}
`, "",{"version":3,"sources":["webpack://./src/app/editor/SheetData.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,eAAe;EACf,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".card-slot {\n  min-height: 2.6em;\n  margin-top: 0.7em;\n  margin-bottom: 0.7em;\n}\n\n.card-list {\n  padding-bottom: 2em;\n  max-height: 80%;\n  margin-top: 0em;\n  overflow-y: scroll;\n  overflow-x: hidden;\n  padding-left: 8px;\n  padding-right: 8px;\n  margin-right: 0.5em;\n  margin-bottom: 2em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
