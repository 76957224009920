// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-sync-configurator {
  height: 100%;
}

.data-sync-configurator .copy-container {
  height: 25%;
}

.data-sync-configurator .list-container {
  height: 75%;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/app/wizard/data-sync-configurator/DataSyncConfigurator.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".data-sync-configurator {\n  height: 100%;\n}\n\n.data-sync-configurator .copy-container {\n  height: 25%;\n}\n\n.data-sync-configurator .list-container {\n  height: 75%;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
