import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'

import { selectUser } from '../common/store/app.js'
import { getLocalizedParts, isPartMatched } from './RowTitle.js'

const RowTitle = ({ title, searchText }) => {
  const user = useSelector(selectUser)
  const parts = getLocalizedParts(title, searchText)

  return (
    <p className='mapping-name' title='row-title'>
      {parts.map((part, i) =>
        isPartMatched(part, searchText, user.locale)
          ? (
            <mark className='mapping-name-highlighted' key={`${part}-${i}`}>
              {part}
            </mark>
            )
          : (
              part
            )
      )}
    </p>
  )
}

RowTitle.propTypes = {
  title: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired
}

export default RowTitle
