// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mappings-list {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.list-header {
  padding: 1rem;
}

.heading-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: top;
}

.heading-group {
  flex-shrink: 0;
  margin: 0 24px;
}

.list-items {
  padding: 1rem 32px 40px 32px;
  overflow-y: auto;
}

.no-results {
  margin: 4rem auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/app/mappings-list/MappingsList.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,4BAA4B;EAC5B,gBAAgB;AAClB;;AAEA;EACE,wBAAwB;EACxB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB","sourcesContent":[".mappings-list {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n}\n\n.list-header {\n  padding: 1rem;\n}\n\n.heading-bar {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: top;\n}\n\n.heading-group {\n  flex-shrink: 0;\n  margin: 0 24px;\n}\n\n.list-items {\n  padding: 1rem 32px 40px 32px;\n  overflow-y: auto;\n}\n\n.no-results {\n  margin: 4rem auto 0 auto;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
