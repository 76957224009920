import { FC, useState, useEffect } from 'react'
import { Toggle } from '@smartsheet/lodestar-core'

const defaultModuleName = process.env.npm_package_name as string
const fileName = process.env.frontend_bundle_path as string
const defaultOverrideUrl = `http://localhost:8083/${fileName}.js`

interface ImportMapOverrides {
  [key: string]: (...args: any[]) => any
}

const getImportMapOverrides = (): ImportMapOverrides => {
  // @ts-expect-error
  if (window.importMapOverrides !== undefined) return window.importMapOverrides
  return {
    getOverrideMap: () => ({ imports: {} }),
    addOverride: () => {},
    resetOverrides: () => {}
  }
}

const isLocallyOverriden = (): boolean => {
  const importMapOverrides = getImportMapOverrides()
  const { imports } = importMapOverrides.getOverrideMap()
  return imports[defaultModuleName] !== undefined
}

const addOverride = (): void => {
  getImportMapOverrides().addOverride(defaultModuleName, defaultOverrideUrl)
}

const resetOverride = getImportMapOverrides().resetOverrides

const DevOverride: FC<{ condition?: boolean }> = ({ condition = true }) => {
  const [isOverriding, setIsOverriding] = useState(isLocallyOverriden())
  const [isLoading, setIsLoading] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const handleClick = (): void => {
    setIsLoading(true)
    isOverriding ? resetOverride() : addOverride()
    setIsOverriding(!isOverriding)
    location.reload()
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (!condition) return
      if (event.ctrlKey && event.key === 'i') {
        event.preventDefault()
        setIsDialogOpen((prevState) => !prevState)
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  if (!isDialogOpen) return null

  return (
    <div css={{ position: 'absolute' }}>
      <Toggle
        isChecked
        isDisabled={isLoading}
        label='Local dev'
        size='large'
        onClick={handleClick}
      />
    </div>
  )
}

export default DevOverride
