// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.column-card {
  cursor: pointer;
  height: 2.6em;
  border-radius: 2px;
  background-color: #ffffff;
  overflow: hidden;
  display: flex;
  border: solid 2px transparent;
}

.column-card:hover {
  border: solid 2px #6097d2;
}

.column-card .column-icon {
  width: 16px;
  height: 16px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0.6em;
  margin-right: 0.6em;
}

.column-card .column-name {
  height: 1.1em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #161616;

  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  max-width: 90%;
  overflow: hidden;

  margin-top: auto;
  margin-bottom: auto;
}

.column-card .column-used {
  background-color: #5f7dc5;

  border-radius: 50%;

  width: 0.6em;
  height: 0.6em;

  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: 1em;
}
`, "",{"version":3,"sources":["webpack://./src/app/editor/ColumnCard.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,kBAAkB;EAClB,yBAAyB;EACzB,gBAAgB;EAChB,aAAa;EACb,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;EACnB,sBAAsB;EACtB,cAAc;;EAEd,uBAAuB;EACvB,mBAAmB;EACnB,cAAc;EACd,cAAc;EACd,gBAAgB;;EAEhB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;;EAEzB,kBAAkB;;EAElB,YAAY;EACZ,aAAa;;EAEb,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;EACjB,iBAAiB;AACnB","sourcesContent":[".column-card {\n  cursor: pointer;\n  height: 2.6em;\n  border-radius: 2px;\n  background-color: #ffffff;\n  overflow: hidden;\n  display: flex;\n  border: solid 2px transparent;\n}\n\n.column-card:hover {\n  border: solid 2px #6097d2;\n}\n\n.column-card .column-icon {\n  width: 16px;\n  height: 16px;\n  margin-top: auto;\n  margin-bottom: auto;\n  margin-left: 0.6em;\n  margin-right: 0.6em;\n}\n\n.column-card .column-name {\n  height: 1.1em;\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: normal;\n  letter-spacing: normal;\n  color: #161616;\n\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  display: block;\n  max-width: 90%;\n  overflow: hidden;\n\n  margin-top: auto;\n  margin-bottom: auto;\n}\n\n.column-card .column-used {\n  background-color: #5f7dc5;\n\n  border-radius: 50%;\n\n  width: 0.6em;\n  height: 0.6em;\n\n  margin-top: auto;\n  margin-bottom: auto;\n  margin-left: auto;\n  margin-right: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
