export const MAPPING_TYPES = {
  native: 'native',
  docusign: 'docusign'
}

export function getMappingType (mapping) {
  if (mapping) {
    if (isDocuSignMapping(mapping)) {
      return MAPPING_TYPES.docusign
    } else if (isNativeMapping(mapping)) {
      return MAPPING_TYPES.native
    } else {
      return undefined
    }
  } else {
    return null
  }
}

export function isDocuSignMapping (mapping) {
  return !!mapping?.docusign
}

export function isNativeMapping (mapping) {
  return !!mapping?.document
}

export function isReadyToSave (mapping) {
  if (!mapping) return false
  // Basic requirements to save any type of Mapping
  const basicRequirements = !!(
    mapping.name &&
    mapping.smartsheetObject &&
    mapping.smartsheetObject.id &&
    mapping.smartsheetObject.type
  )
  // Requirements to save a "native" Mapping
  const nativeRequirements = !!(
    mapping.document &&
    mapping.document.id &&
    mapping.document.filename &&
    mapping.document.userId
  )
  // Requirements to save a DocuSign Mapping
  const docusignRequirements = !!(
    mapping.docusign &&
    mapping.docusign.templateId &&
    mapping.docusign.accountId &&
    mapping.docusign.recipients
  )

  if (isDocuSignMapping(mapping)) {
    return !!(basicRequirements && docusignRequirements)
  }

  if (isNativeMapping(mapping)) {
    return !!(basicRequirements && nativeRequirements)
  }

  return false
}

export const getPairingList = (currentMapping) => {
  if (!currentMapping) return []

  let pairings
  if (isNativeMapping(currentMapping)) {
    pairings = { ...currentMapping?.fieldPairings }
  }
  if (isDocuSignMapping(currentMapping)) {
    pairings = { ...currentMapping.docusign?.tabPairings }
  }

  if (
    currentMapping.outputName?.columnId &&
    currentMapping.outputName.type === 'column'
  ) {
    pairings = { ...pairings, outputName: currentMapping.outputName }
  }

  return pairings ? Object.values(pairings) : []
}
