import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { replacePdf } from '../common/SmartfillApi'
import {
  selectCurrentMapping,
  updateCurrentMapping
} from '../common/store/mapping'

// an abstraction for replacing pdf data for a mapping
const usePdfReplace = () => {
  const dispatch = useDispatch()

  const currentMapping = useSelector(selectCurrentMapping)
  const isBeingEdited = !!currentMapping

  const [previousPdfData, setPreviousPdfData] = useState(null)
  const [changeStats, setChangeStats] = useState(null)

  useEffect(() => {
    // reset hook after mapping is saved/cleared
    if (!isBeingEdited) {
      setPreviousPdfData(null)
      setChangeStats(null)
    }
  }, [isBeingEdited])

  const getChangeQty = (mapping) =>
    mapping.fieldPairings ? Object.keys(mapping.fieldPairings).length : 0

  // replace the pdf attached to the current mapping
  const replaceLocalPdf = (file) => {
    if (!isBeingEdited) throw new Error('a mapping is not being edited')
    if (!currentMapping.document?.id) {
      throw new Error('this mapping does not have a pdf uploaded')
    }

    setPreviousPdfData({
      document: currentMapping.document,
      fieldPairings: currentMapping.fieldPairings || {}
    })

    return replacePdf(file, currentMapping).then((response) => {
      dispatch(
        updateCurrentMapping({
          document: response.data.mapping.document,
          fieldPairings: response.data.mapping.fieldPairings
        })
      )

      setChangeStats({
        additions: response.data.additions.length,
        deletions: response.data.deletions.length,
        updating: getChangeQty(response.data.mapping)
      })
    })
  }

  // reverts changes done by the last call to replacePdf
  const revertChanges = () => {
    if (!isBeingEdited) {
      return Promise.reject(new Error('a mapping is not being edited'))
    } else if (!previousPdfData) {
      return Promise.reject(new Error('the pdf for this mapping has not been changed'))
    } else {
      dispatch(updateCurrentMapping(previousPdfData))
      setPreviousPdfData(null)
      setChangeStats(null)
      return Promise.resolve()
    }
  }

  return { changeStats, replacePdf: replaceLocalPdf, revertChanges }
}

export { usePdfReplace }
