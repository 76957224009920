// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert-bar {
  display: flex;
  color: #111111;
  background-color: #fdd16e;
  padding: 16px;
  margin-bottom: 1.3em;
  margin-top: 1em;
}

.alert-bar .p {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

.alert-bar .icon {
  margin-bottom: auto;
  margin-top: auto;
  margin-right: 1em;
  width: 1.5em;
  height: 1.5em;
}
`, "",{"version":3,"sources":["webpack://./src/app/common/AlertBar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,yBAAyB;EACzB,aAAa;EACb,oBAAoB;EACpB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,kBAAkB;EAClB,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;EACZ,aAAa;AACf","sourcesContent":[".alert-bar {\n  display: flex;\n  color: #111111;\n  background-color: #fdd16e;\n  padding: 16px;\n  margin-bottom: 1.3em;\n  margin-top: 1em;\n}\n\n.alert-bar .p {\n  font-size: 14px;\n  font-weight: 500;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.29;\n  letter-spacing: normal;\n}\n\n.alert-bar .icon {\n  margin-bottom: auto;\n  margin-top: auto;\n  margin-right: 1em;\n  width: 1.5em;\n  height: 1.5em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
