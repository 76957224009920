// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login {
  max-width: 500px;
  margin: auto;
  margin-top: 2em;
  background-color: #f8f7f5;
}

.login--spacer {
  height: 2em;
  background-color: #003059;
}

.login--content {
  display: flex;
  flex-direction: column;
  row-gap: 2em;
  padding: 2em;
  border: 2px solid #e0e0e0;
  border-top: none;
}
`, "",{"version":3,"sources":["webpack://./src/app/auth/Login.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,gBAAgB;AAClB","sourcesContent":[".login {\n  max-width: 500px;\n  margin: auto;\n  margin-top: 2em;\n  background-color: #f8f7f5;\n}\n\n.login--spacer {\n  height: 2em;\n  background-color: #003059;\n}\n\n.login--content {\n  display: flex;\n  flex-direction: column;\n  row-gap: 2em;\n  padding: 2em;\n  border: 2px solid #e0e0e0;\n  border-top: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
