export const smartsheetAccessLevels = {
  viewer: 'VIEWER',
  editor: 'EDITOR',
  editorShare: 'EDITOR_SHARE',
  admin: 'ADMIN',
  owner: 'OWNER'
}

const smartsheetAccessLevelHierarchy = {
  VIEWER: 0,
  EDITOR: 1,
  EDITOR_SHARE: 2,
  ADMIN: 3,
  OWNER: 4
}

// Allow: Owner, Admin, Editor
// Deny: Viewer
export function userCanAttachToRows (sheet) {
  if (!sheet || !sheet.accessLevel) return false
  let accessLevel = sheet.accessLevel
  // Need to check every selected row when using a Report
  // since the access levels may be different.
  if ('sourceSheets' in sheet) {
    accessLevel = getLowestAccessLevel(sheet)
  }
  switch (accessLevel) {
    case smartsheetAccessLevels.editor:
      return true
    case smartsheetAccessLevels.editorShare:
      return true
    case smartsheetAccessLevels.admin:
      return true
    case smartsheetAccessLevels.owner:
      return true
    default:
      return false
  }
}

// Allow: Owner, Admin, Editor
// Deny: Viewer
export function userCanCreateMappings (sheet) {
  if (!sheet) return false
  switch (sheet.accessLevel) {
    case smartsheetAccessLevels.editor:
      return true
    case smartsheetAccessLevels.editorShare:
      return true
    case smartsheetAccessLevels.admin:
      return true
    case smartsheetAccessLevels.owner:
      return true
    default:
      return false
  }
}

// Allow: Owner, Admin
// Deny: Editor, EditorShare, Viewer
export function userCanCreateDocuSignMappings (sheet) {
  if (!sheet) return false
  switch (sheet.accessLevel) {
    case smartsheetAccessLevels.admin:
      return true
    case smartsheetAccessLevels.owner:
      return true
    default:
      return false
  }
}

export function userCanEditMapping (sheet, mapping, user) {
  if (!sheet) return false
  switch (sheet.accessLevel) {
    case smartsheetAccessLevels.editorShare:
    case smartsheetAccessLevels.editor:
      // Editors can only edit mappings they created
      if (mapping && user && mapping.ownerId === user.id) return true
      return false
    case smartsheetAccessLevels.admin:
      return true
    case smartsheetAccessLevels.owner:
      return true
    default:
      return false
  }
}

// Checks the access level of all rows in the grid and returns the lowest value
export function getLowestAccessLevel (report) {
  if (!report) return smartsheetAccessLevels.viewer
  let lowestAccessLevel = report.accessLevel
  report.rows.forEach((row) => {
    if (
      smartsheetAccessLevelHierarchy[row.accessLevel] <
      smartsheetAccessLevelHierarchy[lowestAccessLevel]
    ) { lowestAccessLevel = row.accessLevel }
  })
  return lowestAccessLevel
}
