// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.generate-menu {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);
  padding: 8px;
}

.generate-control .generate-menu .generate-option {
  width: 100%;
  background-color: #ffffff;
}

.generate-option:hover .option-button {
  background-color: #ebebeb !important;
}

.generate-option .option-button {
  width: 100%;
  border: none;
  border-radius: 4px;
  background-color: #ffffff !important;
  text-align: left;
  font-size: 13px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #111111;
  padding: 11px 8px 11px 8px;
}
`, "",{"version":3,"sources":["webpack://./src/app/mappings-list/GenerateControl.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,4CAA4C;EAC5C,YAAY;AACd;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,oCAAoC;EACpC,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,kBAAkB;EAClB,iBAAiB;EACjB,sBAAsB;EACtB,cAAc;EACd,0BAA0B;AAC5B","sourcesContent":[".generate-menu {\n  background-color: #ffffff;\n  border-radius: 4px;\n  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);\n  padding: 8px;\n}\n\n.generate-control .generate-menu .generate-option {\n  width: 100%;\n  background-color: #ffffff;\n}\n\n.generate-option:hover .option-button {\n  background-color: #ebebeb !important;\n}\n\n.generate-option .option-button {\n  width: 100%;\n  border: none;\n  border-radius: 4px;\n  background-color: #ffffff !important;\n  text-align: left;\n  font-size: 13px;\n  font-weight: 400;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.38;\n  letter-spacing: normal;\n  color: #111111;\n  padding: 11px 8px 11px 8px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
