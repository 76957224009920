import {
  Checkbox,
  ScrollArea,
  Toggle,
  TypeRamp
} from '@smartsheet/lodestar-core'
import omit from 'lodash/omit'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { selectSheet } from '../../common/store/app'
import {
  selectCurrentMapping,
  updateCurrentMapping
} from '../../common/store/mapping'

import './DataSyncConfigurator.css'

const DataSyncConfigurator = ({ setComplete }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const sheet = useSelector(selectSheet)
  const currentMapping = useSelector(selectCurrentMapping)

  const dataSyncReferences = currentMapping.docusign.dataSyncReferences || {}

  const [isSyncEnabled, setIsSyncEnabled] = useState(
    !!Object.keys(dataSyncReferences).length
  )

  const setDataSync = (columns) => {
    dispatch(
      updateCurrentMapping({
        docusign: {
          ...currentMapping.docusign,
          dataSyncReferences: columns
        }
      })
    )
    setComplete(true)
  }

  useEffect(() => {
    if (!isSyncEnabled) {
      setDataSync({})
    }
  }, [isSyncEnabled])

  const toggleChecked = (columnId) => {
    if (Object.prototype.hasOwnProperty.call(dataSyncReferences, columnId)) {
      setDataSync(omit(dataSyncReferences, columnId))
    } else {
      setDataSync({
        ...dataSyncReferences,
        [columnId]: []
      })
    }
  }

  return (
    <div className='data-sync-configurator'>
      <div className='copy-container'>
        <TypeRamp variant='headlineMedium' renderAs='h2'>
          {t('wizard.screens.docusign.dataSyncConfig.stepName')}
        </TypeRamp>
        <p>{t('wizard.screens.docusign.dataSyncConfig.subheader')}</p>
        <Toggle
          isChecked={isSyncEnabled}
          onClick={() => setIsSyncEnabled((prev) => !prev)}
          label={t('wizard.screens.docusign.dataSyncConfig.enableLabel')}
        />
      </div>
      <div className='list-container'>
        {isSyncEnabled && (
          <ScrollArea gutter='xSmall' lockPageScroll>
            {sheet.columns
              .filter(
                (col) => col.id !== currentMapping.docusign.statusColumnId
              )
              .map((col) => (
                <div className='list-item' key={col.id}>
                  <Checkbox
                    checkedState={
                      Object.prototype.hasOwnProperty.call(dataSyncReferences, col.id.toString())
                        ? 'checked'
                        : 'unchecked'
                    }
                    onClick={() => toggleChecked(col.id.toString())}
                    label={col.title}
                    isDisabled={Object.prototype.hasOwnProperty.call(col, 'systemColumnType')}
                    useAriaDisabled
                  />
                </div>
              ))}
          </ScrollArea>
        )}
      </div>
    </div>
  )
}

DataSyncConfigurator.propTypes = {
  isInitialized: PropTypes.bool.isRequired,
  setComplete: PropTypes.func.isRequired
}

export default DataSyncConfigurator
