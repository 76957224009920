import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalFooterButton,
  ModalHeader
} from '@smartsheet/lodestar-core'
import { AlertErrorAltIcon } from '@smartsheet/lodestar-icons'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import DocuSignAuthRetry from '../auth/DocuSignAuthRetry'
import { selectSmartsheetObject } from './store/app'
import { clearError, selectError } from './store/error'
import { getErrorDetails } from './util/errors'

import './ErrorModal.css'

const ErrorModal = ({ onClose = () => {} }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { error } = useSelector(selectError)
  const smartsheetObject = useSelector(selectSmartsheetObject)

  const handleClose = () => {
    dispatch(clearError())
    onClose()
  }

  const handleContactUs = () => {
    window.open('https://help.smartsheet.com/contact', '_blank')
    handleClose()
  }

  const errorDetails = error ? getErrorDetails(t, error) : {}

  return (
    <>
      {error && (
        <Modal
          className='error-modal'
          isOpen
          onCloseRequested={() => handleClose()}
          shouldCloseOnOverlayClick={false}
        >
          <ModalHeader
            className='modal-header'
            onCloseRequested={() => handleClose()}
            title={t('errorModal.header', 'Error')}
            titleIcon={<AlertErrorAltIcon className='alert-error-icon' />}
          />
          <ModalBody className='modal-body'>
            <span
              dangerouslySetInnerHTML={{
                __html: errorDetails.msg
              }}
            />
            {error.template &&
              error.template !== errorDetails.msg &&
              !error.template.includes(
                '/usr/local/go/src/net/http/server.go'
              ) && (
                <details className='error-details'>
                  <summary>
                    <span>{t('errorModal.learnMore')}</span>
                  </summary>
                  <div>{error.template}</div>
                </details>
            )}
          </ModalBody>
          <ModalFooter className='modal-footer'>
            {(errorDetails.showContactUs || errorDetails.showLogin) && (
              <ModalFooterButton
                appearance='secondary'
                onClick={handleClose}
                data-dd-action-name='smar:ss.modal.dismiss.btn'
              >
                {t(['errorModal.dismiss', 'Dismiss'])}
              </ModalFooterButton>
            )}

            {!errorDetails.showContactUs && !errorDetails.showLogin && (
              <ModalFooterButton
                appearance='primary'
                onClick={handleClose}
                data-dd-action-name='smar:ss.modal.ok.btn'
              >
                {t(['errorModal.ack', 'OK'])}
              </ModalFooterButton>
            )}

            {errorDetails.showContactUs && (
              <ModalFooterButton
                appearance={errorDetails.showLogin ? 'secondary' : 'primary'}
                onClick={handleContactUs}
                data-dd-action-name='smar:ss.modal.contact.btn'
              >
                {t(['errorModal.contact', 'Contact us'])}
              </ModalFooterButton>
            )}

            {errorDetails.showLogin && smartsheetObject && (
              <DocuSignAuthRetry closeModal={handleClose} />
            )}
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

ErrorModal.propTypes = {
  onClose: PropTypes.func
}

export default ErrorModal
