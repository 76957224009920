import deburr from 'lodash/deburr'

const mappingFilterFns = {
  all: () => true,
  pdf: (mapping) => Object.prototype.hasOwnProperty.call(mapping, 'document'),
  docusign: (mapping) => Object.prototype.hasOwnProperty.call(mapping, 'docusign')
}

export const getMappingFilterFn = (option) => {
  const fn = mappingFilterFns[option.value]

  if (!fn) {
    throw new Error('filter option is not valid')
  }

  return (mapping) => fn(mapping)
}

export const filterOptions = [
  {
    label: 'sortAndFilter.filterOptions.all',
    labelSelected: 'sortAndFilter.filterOptions.allSelected',
    value: 'all'
  },
  {
    label: 'sortAndFilter.filterOptions.pdf',
    labelSelected: 'sortAndFilter.filterOptions.pdfSelected',
    value: 'pdf'
  },
  {
    label: 'sortAndFilter.filterOptions.docusign',
    labelSelected: 'sortAndFilter.filterOptions.docusignSelected',
    value: 'docusign'
  }
]

export const defaultFilterOption = filterOptions[0]

export const searchFilter = (mappingName, searchText, locale) => {
  locale = locale.slice(0, 2)
  mappingName = deburr(mappingName).toLocaleLowerCase(locale)
  searchText = deburr(searchText).toLocaleLowerCase(locale)

  return mappingName.includes(searchText)
}
