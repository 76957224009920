// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.step-review-row {
  display: flex;
  justify-content: space-between;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
  border-radius: 0.2rem;
  padding: 0.8rem;
  margin-bottom: 0.8rem;
}

.step-review-row:hover {
  box-shadow: 0 4px 16px 0 rgb(0 0 0 / 12%);
}

.step-review-row:hover .edit-button {
  display: block;
  margin-top: 0.4rem;
}

.step-review-row .copy-container {
  width: 33rem;
}

.step-review-row .copy-container h3 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0.5rem 0;
}

.step-review-row .copy-container p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0.5rem 0;
}

.step-review-row .edit-button {
  display: none;
  margin-top: 0.4rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/wizard/settings-reviewer/StepReviewRow.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,uCAAuC;EACvC,qBAAqB;EACrB,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB","sourcesContent":[".step-review-row {\n  display: flex;\n  justify-content: space-between;\n  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);\n  border-radius: 0.2rem;\n  padding: 0.8rem;\n  margin-bottom: 0.8rem;\n}\n\n.step-review-row:hover {\n  box-shadow: 0 4px 16px 0 rgb(0 0 0 / 12%);\n}\n\n.step-review-row:hover .edit-button {\n  display: block;\n  margin-top: 0.4rem;\n}\n\n.step-review-row .copy-container {\n  width: 33rem;\n}\n\n.step-review-row .copy-container h3 {\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  margin: 0.5rem 0;\n}\n\n.step-review-row .copy-container p {\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  margin: 0.5rem 0;\n}\n\n.step-review-row .edit-button {\n  display: none;\n  margin-top: 0.4rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
