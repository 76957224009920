import axios from 'axios'

import auth from '../auth/authUtil'

const API_ROOT = process.env.BASE_API_URL + '/api'

export const getPDF = (id) =>
  axios
    .get(
      `${API_ROOT}/pdf/get/${id}`,
      auth.requestConfig({
        responseType: 'blob',
        headers: {
          Accept: 'application/pdf'
        }
      })
    )
    .then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/pdf'
      })
      return Promise.resolve(blob)
    })

export const uploadPDF = (file) => {
  const formData = new FormData()
  formData.append('file', file)
  return axios.post(
    `${API_ROOT}/pdf/upload`,
    formData,
    auth.requestConfig({
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  )
}

export const generateDocuSignEnvelopes = (
  mappingId,
  parentSmartsheetObject,
  rowIds,
  draft
) => {
  const payload = { mappingId, parentSmartsheetObject, rowIds, draft }
  return axios.post(
    `${API_ROOT}/generate/docusign/envelopes`,
    payload,
    auth.docusignRequestConfig({}, parentSmartsheetObject)
  )
}

export const generate = (
  mappingId,
  parentSmartsheetObject,
  outputOption,
  rowIds
) => {
  const payload = {
    mappingId,
    parentSmartsheetObject,
    outputOption,
    rowIds
  }
  return axios.post(`${API_ROOT}/pdf/fill`, payload, auth.requestConfig())
}

export const preview = (mapping, rowIds) => {
  const payload = { mapping, rowIds }
  return axios.post(`${API_ROOT}/pdf/preview`, payload, auth.requestConfig())
}

export const upsertMapping = (mapping) => {
  return axios.post(`${API_ROOT}/mappings`, mapping, auth.requestConfig())
}

export const replacePdf = (file, mapping) => {
  const formData = new FormData()
  const obj = mapping.smartsheetObject
  formData.append('file', file)
  return axios.post(
    `${API_ROOT}/pdf/replace/${mapping.id}?parentId=${obj.id}&parentType=${obj.type}`,
    formData,
    auth.requestConfig({
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  )
}

export const getMappingsList = (smartsheetObject) => {
  return axios.get(
    `${API_ROOT}/mappings?parentId=${smartsheetObject.id}&parentType=${smartsheetObject.type}`,
    auth.requestConfig()
  )
}

export const getMapping = (mappingId) => {
  return axios.get(`${API_ROOT}/pdf/mapping/${mappingId}`, auth.requestConfig())
}

export const deleteMapping = (mapping) => {
  const obj = mapping.smartsheetObject
  return axios.delete(
    `${API_ROOT}/mappings/${mapping.id}?parentId=${obj.id}&parentType=${obj.type}`,
    auth.requestConfig()
  )
}

export const getJobStatus = (jobId) => {
  return axios.get(`${API_ROOT}/jobs/${jobId}`, auth.requestConfig())
}

export const checkFeatureAccess = (featureName) => {
  return axios.get(`${API_ROOT}/ff/${featureName}`, auth.requestConfig())
}

/// SMARTSHEET ///
export const getCurrentUser = () => {
  return axios.get(`${API_ROOT}/smartsheet/me`, auth.requestConfig())
}

export const searchAll = (query) => {
  return axios.get(
    `${API_ROOT}/smartsheet/search`,
    auth.requestConfig({
      params: { query }
    })
  )
}

export const fetchSheetData = (sheetId, rowIdFilter) => {
  return axios.post(
    `${API_ROOT}/smartsheet/sheets`,
    {
      id: sheetId,
      rowIds: rowIdFilter
    },
    auth.requestConfig()
  )
}

export const fetchReportData = (reportId) => {
  return axios.get(
    `${API_ROOT}/smartsheet/reports/${reportId}`,
    auth.requestConfig()
  )
}

export const getMaskedId = (directId) => {
  return axios.get(
    `${API_ROOT}/smartsheet/maskedid/${directId}`,
    auth.requestConfig()
  )
}

export const createStatusColumn = (parentObject, columnName) => {
  return axios.post(
    `${API_ROOT}/smartsheet/columns/sigstatus`,
    { parentObject, columnName },
    auth.requestConfig()
  )
}

/// DOCUSIGN ///
export const getDocuSignConfig = () => {
  return axios.get(`${API_ROOT}/docusign/config`, auth.requestConfig())
}

// Sends the OAuth code from DocuSign back to the server
export const postDocuSignCode = (data) => {
  return axios.post(
    `${API_ROOT}/oauth/docusign/token`,
    data,
    auth.requestConfig({ headers: { 'X-Smartsheet-TokenID': data.state } })
  )
}

export const getDocuSignAccount = (smartsheetObject) => {
  return axios.get(
    `${API_ROOT}/docusign/account`,
    auth.docusignRequestConfig({}, smartsheetObject)
  )
}

export const getDocuSignAuthContext = (smartsheetObject) => {
  return axios.get(
    `${API_ROOT}/docusign/account/context`,
    auth.docusignRequestConfig({}, smartsheetObject)
  )
}

export const logoutDocuSignAccount = (smartsheetObject) => {
  return axios.delete(
    `${API_ROOT}/docusign/account`,
    auth.docusignRequestConfig({}, smartsheetObject)
  )
}

export const getDocuSignPDF = (id, smartsheetObject) =>
  axios
    .get(
      `${API_ROOT}/docusign/templates/${id}/pdf`,
      auth.docusignRequestConfig(
        {
          responseType: 'blob',
          headers: {
            Accept: 'application/pdf'
          }
        },
        smartsheetObject
      )
    )
    .then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/pdf'
      })
      return Promise.resolve(blob)
    })

export const listDocuSignTemplates = (smartsheetObject) => {
  return axios.get(
    `${API_ROOT}/docusign/templates`,
    auth.docusignRequestConfig({}, smartsheetObject)
  )
}

export const getDocuSignTemplate = (id, smartsheetObject) => {
  return axios.get(
    `${API_ROOT}/docusign/templates/${id}`,
    auth.docusignRequestConfig({}, smartsheetObject)
  )
}

export const listDocuSignTemplateRecipients = (id, smartsheetObject) => {
  return axios.get(
    `${API_ROOT}/docusign/templates/${id}/recipients`,
    auth.docusignRequestConfig({}, smartsheetObject)
  )
}

export const listDocuSignTemplateDocuments = (id, smartsheetObject) => {
  return axios.get(
    `${API_ROOT}/docusign/templates/${id}/documents`,
    auth.docusignRequestConfig({}, smartsheetObject)
  )
}

export const previewDocuSignEmails = (templateId, rowId, smartsheetObject) => {
  return axios.get(
    `${API_ROOT}/docusign/templates/${templateId}/emails/${rowId}?parentId=${smartsheetObject.id}&parentType=${smartsheetObject.type}`,
    auth.docusignRequestConfig({}, smartsheetObject)
  )
}

export const logoutUser = () => {
  return axios.delete(`${API_ROOT}/auth/logout`, auth.requestConfig())
}
