// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mapping-type-selector label {
  margin-top: 0.5rem;
}

.mapping-type-selector i.upsell.icon {
  width: 24px;
  height: 24px;
  margin-right: 0;
  margin-left: 0.5em;
  margin-top: 0em;
  margin-bottom: auto;
}

.mapping-type-selector .upsell-wrapper {
  float: right;
}
`, "",{"version":3,"sources":["webpack://./src/app/wizard/mapping-type-selector/MappingTypeSelector.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".mapping-type-selector label {\n  margin-top: 0.5rem;\n}\n\n.mapping-type-selector i.upsell.icon {\n  width: 24px;\n  height: 24px;\n  margin-right: 0;\n  margin-left: 0.5em;\n  margin-top: 0em;\n  margin-bottom: auto;\n}\n\n.mapping-type-selector .upsell-wrapper {\n  float: right;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
