import React, { useEffect, useRef, useState } from 'react'

import {
  CheckboxIcon,
  ContactsMultiIcon,
  ContactsSingleIcon,
  DateIcon,
  DropdownMultiIcon,
  DropdownSingleIcon,
  DurationIcon,
  TextIcon
} from '../common/icon/ColumnIcons'
import { emitEvent } from '../common/util/events'

import './ColumnCard.css'

// this component is slated for a future re-write and has limited test coverage; keep changes minimal
const ColumnCard = ({ isPaired, column }) => {
  const [isDragging, setIsDragging] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [offset, setOffset] = useState({ x: 0, y: 0 })

  const cardRef = useRef()
  const widthRef = useRef()

  const getIcon = () => {
    if (!column) return null
    switch (column.type) {
      case 'CONTACT_LIST':
        return <ContactsSingleIcon />
      case 'MULTI_CONTACT_LIST':
        return <ContactsMultiIcon />
      case 'PICKLIST':
        return <DropdownSingleIcon />
      case 'MULTI_PICKLIST':
        return <DropdownMultiIcon />
      case 'DATE':
      case 'DATETIME':
      case 'ABSTRACT_DATETIME':
        return <DateIcon />
      case 'CHECKBOX':
        return <CheckboxIcon />
      case 'DURATION':
        return <DurationIcon />
      case 'TEXT_NUMBER':
      case 'PREDECESSOR':
      default:
        return <TextIcon />
    }
  }

  const getPosition = () => {
    const style = {
      userSelect: 'none',
      borderRadius: 2
    }

    if (isDragging) {
      style.position = 'fixed'
      style.opacity = 0.7
      style.top = position.y + offset.y
      style.left = position.x + offset.x
      style.width = widthRef.current + 'px'
      style.zIndex = 5
    }

    return style
  }

  useEffect(() => {
    return () => {
      document.removeEventListener('mousemove', drag)
      document.removeEventListener('mouseup', dragEnd)
    }
  }, [])

  const dragStart = (event) => {
    const rect = cardRef.current.getBoundingClientRect()
    // Freeze the width of the card so it doesn't expand when we remove it from
    // its container
    widthRef.current = rect.right - rect.left

    document.addEventListener('mousemove', drag)
    document.addEventListener('mouseup', dragEnd)

    const offsetX = rect.left - event.clientX
    const offsetY = rect.top - event.clientY
    setPosition({
      x: rect.left - offsetX,
      y: rect.top - offsetY
    })
    setOffset({
      x: offsetX,
      y: offsetY
    })
    setIsDragging(true)
  }

  const drag = (event) => {
    setPosition({
      x: event.clientX,
      y: event.clientY
    })
    emitEvent('columndrag', {
      pos: {
        positionX: event.clientX,
        positionY: event.clientY
      },
      column,
      columnId: column.id
    })
  }

  const dragEnd = (event) => {
    document.removeEventListener('mousemove', drag)
    document.removeEventListener('mouseup', dragEnd)
    setIsDragging(false)
    emitEvent('columndrop', {
      pos: {
        positionX: event.clientX,
        positionY: event.clientY
      },
      column,
      columnId: column.id
    })
  }

  return (
    <div
      className='column-card'
      key={column.id}
      style={getPosition()}
      onMouseDown={dragStart}
      ref={cardRef}
      data-dd-action-name='smar:column.card.field'
    >
      <div className='column-icon'>{getIcon()}</div>
      <div className='column-name'>{column.title}</div>
      {isPaired ? <div className='column-used' /> : ''}
    </div>
  )
}

export default ColumnCard
