import {
  Banner,
  BannerContent,
  Button,
  TypeRamp
} from '@smartsheet/lodestar-core'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { docuSignAuth } from '../../auth/dsAuthUtil'
import AlertBar from '../../common/AlertBar'
import { logoutDocuSignAccount } from '../../common/SmartfillApi'
import { selectSmartsheetObject } from '../../common/store/app'
import {
  clearDocusignAuth,
  clearDocusignTemplateList,
  fetchDocusignAuth,
  selectDocusignAuth
} from '../../common/store/docusign'
import {
  selectCurrentMapping,
  updateCurrentMapping
} from '../../common/store/mapping'
import { HelpArticleLink } from '../../common/util/docusign'
import { getErrorDetails, parseServerError } from '../../common/util/errors'

const DocuSignAuth = ({ isInitialized, setComplete, onDocuSignLogout }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const docusignAuth = useSelector(selectDocusignAuth)
  const smartsheetObject = useSelector(selectSmartsheetObject)
  const currentMapping = useSelector(selectCurrentMapping)

  const [isConfirmingLogout, setIsConfirmingLogout] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const isDocusignAuthed = !!docusignAuth

  useEffect(() => {
    if (!!docusignAuth && !isInitialized) setDocuSignAccount(docusignAuth)
  }, [docusignAuth, isInitialized])

  const setDocuSignAccount = (authContext) => {
    dispatch(
      updateCurrentMapping({
        docusign: {
          ...currentMapping.docusign,
          accountId: authContext.account?.accountIdGuid || '',
          userName: authContext.user.userName || '',
          email: authContext.user.email || '',
          permissionProfileName: authContext.user.permissionProfileName || ''
        }
      })
    )
    setComplete(true)
  }

  const logout = () => {
    setIsLoading(true)
    logoutDocuSignAccount(smartsheetObject)
      .then(() => onDocuSignLogout())
      .then(() => {
        dispatch(clearDocusignAuth())
        dispatch(clearDocusignTemplateList())
      })
  }

  const handleDocuSignLogIn = () => {
    setIsLoading(true)
    setError(null)
    docuSignAuth(smartsheetObject)
      .then(() => dispatch(fetchDocusignAuth(smartsheetObject)).unwrap())
      .catch((error) => {
        setError(parseServerError(error))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const getButtonType = () => {
    if (isDocusignAuthed) return 'logout'
    if (isLoading) return 'retry'
    return 'login'
  }

  const errorDetails = error ? getErrorDetails(t, error) : {}

  return (
    <div className='docusign-auth'>
      <TypeRamp variant='headlineMedium' renderAs='h2'>
        {t('wizard.screens.docusign.auth.header')}
      </TypeRamp>
      <p>{t('wizard.screens.docusign.auth.subheader')}</p>
      {error && (
        <AlertBar>
          <div>{errorDetails.msg}</div>
          <a href={HelpArticleLink} target='_blank' rel='noopener noreferrer'>
            {t('errorModal.learnMore', 'Learn more')}
          </a>
        </AlertBar>
      )}
      {!error && !isDocusignAuthed && isLoading && (
        <AlertBar>{t('wizard.screens.docusign.auth.popupWarning')}</AlertBar>
      )}
      {isDocusignAuthed && (
        <p className='current-user'>
          {t('wizard.screens.docusign.auth.currentUser', {
            user: docusignAuth?.user?.userName
          })}
          <br />
          {docusignAuth?.user?.email}
          <br />
          {docusignAuth?.user?.permissionProfileName}
        </p>
      )}
      {isConfirmingLogout
        ? (
          <>
            <Banner type='warning' style={{ margin: '1rem 0' }}>
              <BannerContent
                style={{ margin: '0 .7rem' }}
                dangerouslySetInnerHTML={{
                  __html: t(
                  `docusignAuth.logInModal.${
                    smartsheetObject.type === 'sheet'
                      ? 'logoutWarningSheet'
                      : 'logoutWarningReport'
                  }`
                  )
                }}
              />
            </Banner>
            <div className='logout-confirmation'>
              <Button
                appearance='secondary'
                style={{ marginRight: '1rem' }}
                data-dd-action-name='smar:wizard.docusign.auth.cancel.btn'
                onClick={() => setIsConfirmingLogout(false)}
              >
                {t('wizard.screens.docusign.auth.button_cancel')}
              </Button>
              <Button
                appearance='primary'
                className='confirm'
                data-dd-action-name='smar:wizard.docusign.auth.confirm.btn'
                onClick={logout}
                isLoading={isLoading}
              >
                {t('wizard.screens.docusign.auth.button_confirm')}
              </Button>
            </div>
          </>
          )
        : (
          <Button
            appearance={isDocusignAuthed ? 'secondary' : 'primary'}
            data-dd-action-name={
            'smar:wizard.docusign.auth.' + getButtonType() + '.btn'
          }
            onClick={
            isDocusignAuthed
              ? () => setIsConfirmingLogout(true)
              : handleDocuSignLogIn
          }
            isLoading={isLoading}
          >
            {t(`wizard.screens.docusign.auth.button_${getButtonType()}`)}
          </Button>
          )}
    </div>
  )
}

DocuSignAuth.propTypes = {
  isInitialized: PropTypes.bool.isRequired,
  setComplete: PropTypes.func.isRequired,
  onDocuSignLogout: PropTypes.func.isRequired
}

export default DocuSignAuth
