// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.template-selector {
  height: 100%;
}

.template-selector .copy-container {
  height: 20%;
}

.template-selector .list-container {
  height: 80%;
  overflow: hidden;
  /* HACK: Part of spinner hack below. */
  position: relative;
}

/*HACK: Need spinner to float over sibling content during content processing moments only here. 
        Perhaps one day the spinner will be refactored to always float over sibling content. */
.template-selector .list-container .spinner-container {
  position: absolute;
  width: 100%;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/app/wizard/template-selector/TemplateSelector.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,sCAAsC;EACtC,kBAAkB;AACpB;;AAEA;8FAC8F;AAC9F;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd","sourcesContent":[".template-selector {\n  height: 100%;\n}\n\n.template-selector .copy-container {\n  height: 20%;\n}\n\n.template-selector .list-container {\n  height: 80%;\n  overflow: hidden;\n  /* HACK: Part of spinner hack below. */\n  position: relative;\n}\n\n/*HACK: Need spinner to float over sibling content during content processing moments only here. \n        Perhaps one day the spinner will be refactored to always float over sibling content. */\n.template-selector .list-container .spinner-container {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
