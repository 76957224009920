// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.document-viewer {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  -ms-flex-align: center;
  height: 100%;
  padding-top: 1.5em;
  padding-bottom: 15em;
  background-color: #f3f3f3;
}

.react-pdf__Page__canvas {
  border: 1px solid #e4e4e4;
}

.page-controls {
  margin: 1rem auto 0;
  padding: 1rem;
  background-color: #ffffff !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/editor/DocumentViewer.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;EAClB,oBAAoB;EACpB,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,oCAAoC;AACtC","sourcesContent":[".document-viewer {\n  overflow-y: scroll;\n  display: flex;\n  flex-direction: column;\n  -ms-flex-align: center;\n  height: 100%;\n  padding-top: 1.5em;\n  padding-bottom: 15em;\n  background-color: #f3f3f3;\n}\n\n.react-pdf__Page__canvas {\n  border: 1px solid #e4e4e4;\n}\n\n.page-controls {\n  margin: 1rem auto 0;\n  padding: 1rem;\n  background-color: #ffffff !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
