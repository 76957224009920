// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-menu-item:hover .settings-menu-button {
  background-color: #ebebeb !important;
}

.settings-menu-item {
  padding: 0.4em;
  width: 100%;
  display: flex;
  background-color: white;
}

.settings-menu-button {
  color: #111111 !important;
  background-color: #ffffff !important;
  font-size: 13px;
  font-weight: 400 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  text-align: left;
  padding-top: 1em;
  padding-bottom: 1em;
  border: none;
  border-radius: 4px;
}

.settings-menu {
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/app/editor/SettingsMenu.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC;;AAEA;EACE,cAAc;EACd,WAAW;EACX,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;EACzB,oCAAoC;EACpC,eAAe;EACf,2BAA2B;EAC3B,oBAAoB;EACpB,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,4CAA4C;EAC5C,WAAW;AACb","sourcesContent":[".settings-menu-item:hover .settings-menu-button {\n  background-color: #ebebeb !important;\n}\n\n.settings-menu-item {\n  padding: 0.4em;\n  width: 100%;\n  display: flex;\n  background-color: white;\n}\n\n.settings-menu-button {\n  color: #111111 !important;\n  background-color: #ffffff !important;\n  font-size: 13px;\n  font-weight: 400 !important;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.38;\n  text-align: left;\n  padding-top: 1em;\n  padding-bottom: 1em;\n  border: none;\n  border-radius: 4px;\n}\n\n.settings-menu {\n  border-radius: 4px;\n  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
