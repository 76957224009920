// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mapper-main {
  display: flex;
  height: 95%;
  width: 100%;
}

.mapper-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/app/Mapper.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,QAAQ;EACR,OAAO;EACP,SAAS;AACX","sourcesContent":[".mapper-main {\n  display: flex;\n  height: 95%;\n  width: 100%;\n}\n\n.mapper-wrapper {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  right: 0;\n  left: 0;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
