import { getDocuSignConfig } from '../common/SmartfillApi'
import auth from './authUtil'

export const docuSignAuth = async (smartsheetObject) => {
  const response = await getDocuSignConfig()

  // Build the DocuSign OAuth URL
  const conf = response.data
  const loc = window.location
  const docuSignURL =
    `${conf.BaseURL}/oauth/auth?` +
    'response_type=code' +
    '&scope=signature extended' +
    `&client_id=${conf.ClientID}` +
    `&state=${auth.encodeTokenId('DocuSign', smartsheetObject)}` +
    `&redirect_uri=${loc.protocol}//${loc.host}/auth/callback/docusign`

  // Open DocuSign OAuth in a popup so the user doesn't leave the app
  const win = popupWindow(encodeURI(docuSignURL), '_blank', 500, 500)

  return new Promise((resolve) => {
    // Apparently the only way to tell when a popup has been closed is to
    // poll it
    const timer = setInterval(() => {
      if (win && win.closed) {
        // Re-run the check for DocuSign
        clearInterval(timer)
        resolve()
      } else if (win === null) {
        // If popups are blocked, prevent the error page from showing
        clearInterval(timer)
        resolve()
      }
    }, 200)
  })
}

const popupWindow = (url, target, w, h) => {
  const userAgent = navigator.userAgent
  const mobile = () =>
    /\b(iPhone|iP[ao]d)/.test(userAgent) ||
    /\b(iP[ao]d)/.test(userAgent) ||
    /Android/i.test(userAgent) ||
    /Mobile/i.test(userAgent)
  const scale = window.devicePixelRatio
  const screenX =
    typeof window.screenX !== 'undefined' ? window.screenX : window.screenLeft
  const screenY =
    typeof window.screenY !== 'undefined' ? window.screenY : window.screenTop
  const innerWidth =
    typeof window.innerWidth !== 'undefined'
      ? window.innerWidth
      : document.documentElement.clientWidth
  const innerHeight =
    typeof window.innerHeight !== 'undefined'
      ? window.innerHeight
      : document.documentElement.clientHeight - 22
  const targetWidth = mobile() ? 0 : Math.floor(w * scale)
  const targetHeight = mobile() ? 0 : Math.floor(h * scale)
  const V = screenX < 0 ? window.screen.width + screenX : screenX
  const left = parseInt(V + (innerWidth - targetWidth) / 2, 10)
  const top = parseInt(screenY + (innerHeight - targetHeight) / 2.5, 10)
  const features = []

  features.push('scrollbars=1')
  features.push('left=' + left)
  features.push('top=' + top)
  if (targetWidth !== 0) {
    features.push('width=' + targetWidth)
  }
  if (targetHeight !== 0) {
    features.push('height=' + targetHeight)
  }

  const popup = window.open(url, target, features.join(','))
  if (window.focus && popup) {
    popup.moveTo(left, top)
    popup.focus()
  }

  return popup
}
