// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-column-configurator .column-name-input {
  width: 25rem;
  margin-top: 1rem;
}

.status-column-configurator .invalid-name-label {
  color: #d03340;
  margin-top: 0.5rem;
}

.status-column-configurator .invalid-name-label i {
  fill: #d03340;
  vertical-align: sub;
  margin-right: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/wizard/status-column-configurator/StatusColumnConfigurator.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,oBAAoB;AACtB","sourcesContent":[".status-column-configurator .column-name-input {\n  width: 25rem;\n  margin-top: 1rem;\n}\n\n.status-column-configurator .invalid-name-label {\n  color: #d03340;\n  margin-top: 0.5rem;\n}\n\n.status-column-configurator .invalid-name-label i {\n  fill: #d03340;\n  vertical-align: sub;\n  margin-right: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
