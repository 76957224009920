/* global localStorage */

import { datadogRum } from '@datadog/browser-rum'

import { logoutUser } from '../common/SmartfillApi'

// Originally tokens were stashed under the key 'token'
const legacyTokenKey = 'token'
// With the EAP release of DocuSign, we forced logout/login by changing to 'docBuilderToken'
const tokenKeyInsufficientScope = 'docBuilderToken'
// With the introduction of DocuSign signature status tracking,
// we had to force logout/login again to add the "ADMIN_SHEETS" scope to the tokens.
const tokenKey = 'documentBuilderToken'

// Store the user's token info in localStorage
function storeToken (data) {
  localStorage.setItem(tokenKey, data.token)
  localStorage.setItem('expires', data.expires)
}

// Retrieve token info from localStorage
function retrieveToken () {
  return localStorage.getItem(tokenKey)
}

// Check that the token is current and valid
function checkToken () {
  // Legacy auth upgrade path - user will have to log in again
  if (localStorage.getItem(legacyTokenKey) != null) {
    logout()
  }

  // Upgrade path from tokens which lack the ADMIN_SHEETS scope
  if (localStorage.getItem(tokenKeyInsufficientScope) != null) {
    logout()
  }

  const token = retrieveToken()
  return !!token
}

// Token info data encoded into base64
function encodedToken () {
  return localStorage.getItem(tokenKey)
}

// Extends an axios request config to include the proper auth header
function requestConfig (config) {
  if (!config) {
    config = {}
  }
  if (!config.headers) {
    config.headers = {}
  }
  config.headers['Smartsheet-Auth'] = encodedToken()
  return config
}

function docusignRequestConfig (config, smartsheetObject) {
  config = requestConfig(config)
  config.headers['X-Smartsheet-TokenID'] = encodeTokenId(
    'DocuSign',
    smartsheetObject
  )
  return config
}

function logout () {
  logoutUser().catch((_error) => {
    // skip the error as it will be caused by the user doesn't have token in the backend
    console.error('Error logging out')
  })
  // remove the token from localStorage
  localStorage.removeItem(legacyTokenKey)
  localStorage.removeItem(tokenKeyInsufficientScope)
  localStorage.removeItem(tokenKey)

  datadogRum.removeUser()
}

function encodeTokenId (service, smartsheetObject) {
  return `${service}:${smartsheetObject.id}:${smartsheetObject.type}`
}

const errorCodes = Object.freeze({
  API_ERROR_TOKEN_EXPIRED: 1003,
  API_ERROR_TOKEN_INVALID: 1002
})

// Check if it's a session expiry on Smartsheet's end, in which case we want
// to notify the user and ask them to sign in again
function requiresSignIn (errorCode) {
  return (
    errorCode === errorCodes.API_ERROR_TOKEN_EXPIRED ||
    errorCode === errorCodes.API_ERROR_TOKEN_INVALID
  )
}

export default {
  storeToken,
  checkToken,
  retrieveToken,
  requestConfig,
  docusignRequestConfig,
  encodeTokenId,
  logout,
  requiresSignIn,
  errorCodes
}
