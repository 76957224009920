export function filterRows (grid, rowIds) {
  if (!grid || !rowIds) return grid
  grid.rows = grid.rows.filter((row) => rowIds.includes(row.id))
  return grid
}

export function isInside (x, y, fieldRef, hidden) {
  if (!fieldRef || !fieldRef.current || hidden) return false
  const rect = fieldRef.current.getBoundingClientRect()
  // Top and bottom are inverted because of the way the PDF coordinates work
  return x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom
}
