// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-modal .modal-header .alert-error-icon {
  color: #d0011b;
}

.error-modal .modal-body ol,
.error-modal .modal-body ul {
  padding-left: 1.3rem;
}

.error-modal .error-details span {
  color: #0970be;
  font-weight: bold;
}

.error-modal summary {
  color: #444444;
}

.error-modal details {
  cursor: default;
  font-weight: normal;
  color: #727272;
  margin-top: 0.5rem;
  padding: 0.5rem;
}

.error-modal details[open] div {
  padding: 0.6rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/common/ErrorModal.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;;EAEE,oBAAoB;AACtB;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,cAAc;EACd,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".error-modal .modal-header .alert-error-icon {\n  color: #d0011b;\n}\n\n.error-modal .modal-body ol,\n.error-modal .modal-body ul {\n  padding-left: 1.3rem;\n}\n\n.error-modal .error-details span {\n  color: #0970be;\n  font-weight: bold;\n}\n\n.error-modal summary {\n  color: #444444;\n}\n\n.error-modal details {\n  cursor: default;\n  font-weight: normal;\n  color: #727272;\n  margin-top: 0.5rem;\n  padding: 0.5rem;\n}\n\n.error-modal details[open] div {\n  padding: 0.6rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
