import { FieldTypes } from './constants'

export const HelpArticleLink = 'https://help.smartsheet.com/articles/2482231'

export function userHasMinimumPermissions (authContext) {
  const { isAdmin, allowEnvelopeSending, allowedTemplateAccess } = authContext

  const allowedToUseTemplates =
    allowedTemplateAccess === 'create' || allowedTemplateAccess === 'share'

  return isAdmin || (allowEnvelopeSending && allowedToUseTemplates)
}

export function getFieldsFromDocuments (docusignDocuments) {
  if (!docusignDocuments) return []
  const fields = []
  let passedPages = 0

  docusignDocuments.forEach((doc) => {
    Object.values(doc.tabs).forEach((tabType) => {
      if (!tabType) return
      tabType.forEach((tab) => {
        const field = {
          objectNumber: tab.tabId,
          name: tab.tabLabel,
          pageNumber: passedPages + parseInt(tab.pageNumber),
          rect: [0, 0, 0, 0],
          fieldType: getFieldType(tab),
          tab
        }
        fields.push(field)
      })
    })
    //
    passedPages += doc.totalPageCount
  })
  return fields
}

export const getUpdatedDataSyncRefs = (currentMapping, fields) => {
  const { dataSyncReferences, tabPairings } = currentMapping.docusign
  // Data sync column/field references are only updated upon saving
  // the mapping. To account for removed synced fields, changed templates,
  // and changes to the sync columns desired, reference table is built
  // for each save.
  const updatedDataSync = {}
  // Rebuilt reference table should retain synced column settings, even if
  // no tabs are currently mapped.
  for (const columnID in dataSyncReferences) {
    updatedDataSync[columnID] = []
  }
  for (const pairingObjectNumber in tabPairings) {
    const pairing = tabPairings[pairingObjectNumber]
    // Tab syncs if its filled column is a sync column
    if (Object.prototype.hasOwnProperty.call(dataSyncReferences, pairing.columnId)) {
      const tabs = updatedDataSync[pairing.columnId]

      const match = fields.find(
        (field) => field.objectNumber === pairingObjectNumber
      )
      // tabs with the same tablabel are linked fields within Docusign
      if (!!match && !tabs.includes(match.name)) {
        tabs.push(match.name)
      }
      updatedDataSync[pairing.columnId] = tabs
    }
  }

  return updatedDataSync
}

function getFieldType (tab) {
  const fieldType = FieldTypesByTabType[tab.tabType]
  return fieldType || FieldTypes.Unsupported
}

export function getTabPositionStyle (tab) {
  let xOffset = 4
  let yOffset = 0
  let tabWidth = tab.width
  let tabHeight = tab.height

  const minHeight = 16

  // After lots of experimentation, I found that scaling the width down to 87%
  // and the height down to 75% provided the best results across our
  // usual test documents. - Andrew
  const widthScaleFactor = 0.87
  const heightScaleFactor = 1.0

  // Checkbox tabs created in DocuSign have a width & height of 0 by default
  // Need to apply our own default value or they won't appear.
  if (getFieldType(tab) === FieldTypes.Checkbox) {
    tabWidth = 21
    tabHeight = 21
    // Checkbox tabs don't seem to need the same offset as text fields,
    // observed across a variety of different documents.
    xOffset = 2
    yOffset = 1
  }

  if (parseInt(tabHeight) === 0 && parseInt(tabWidth) === 0) {
    tabWidth = 66
    tabHeight = 21
  }

  if (tabHeight < minHeight) {
    tabHeight = minHeight
  }

  return {
    left: Math.abs(parseInt(tab.xPosition)) + xOffset,
    top: Math.abs(parseInt(tab.yPosition)) + yOffset,
    width: parseInt(tabWidth) * widthScaleFactor,
    height: parseInt(tabHeight) * heightScaleFactor
  }
}

// Map between Tab type values and FieldType values
const FieldTypesByTabType = {
  zip: FieldTypes.Text,
  email: FieldTypes.Text,
  date: FieldTypes.Text,
  note: FieldTypes.Text,
  number: FieldTypes.Text,
  ssn: FieldTypes.Text,
  text: FieldTypes.Text,
  list: FieldTypes.Choice,
  checkbox: FieldTypes.Checkbox
}

// Recipients from DocuSign are listed under separate object keys depending on
// the recipient type, but for most interactions we treat them all the same.
export const flattenRecipientsList = (recipients) => {
  if (!recipients) return []

  return Object.values(recipients)
    .filter((val) => Array.isArray(val))
    .flat()
}
