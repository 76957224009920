import {
  Banner,
  BannerContent,
  Modal,
  ModalBody,
  ModalFooter,
  ModalFooterButton,
  ModalHeader,
  Spacer,
  TypeRamp
} from '@smartsheet/lodestar-core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import DocuSignAuthRetry from '../auth/DocuSignAuthRetry'
import { logoutDocuSignAccount } from '../common/SmartfillApi'
import { selectSmartsheetObject } from '../common/store/app'
import {
  clearDocusignAuth,
  clearDocusignTemplateList,
  selectDocusignAuth
} from '../common/store/docusign'
import { setError } from '../common/store/error'

const LogInModal = ({ mapping, setModalMapping }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const smartsheetObject = useSelector(selectSmartsheetObject)
  const docusignAuth = useSelector(selectDocusignAuth)

  const [isLoading, setIsLoading] = useState(false)

  const close = () => setModalMapping(null)

  const handleLogout = () => {
    setIsLoading(true)
    logoutDocuSignAccount(smartsheetObject)
      .then(() => {
        dispatch(clearDocusignAuth())
        dispatch(clearDocusignTemplateList())
      })
      .catch((error) => {
        dispatch(setError(error))
        close()
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <Modal isOpen={!!mapping} onCloseRequested={close}>
      <ModalHeader
        title={t(
          !docusignAuth
            ? 'docusignAuth.mappingRow.logInTag'
            : 'docusignAuth.logInModal.logOutOfDocusign'
        )}
        onCloseRequested={close}
      />
      <ModalBody>
        <Spacer
          orientation='column'
          space='medium'
          style={{ margin: '1rem 0' }}
        >
          <TypeRamp variant='bodyStrong'>
            {t(
              !docusignAuth
                ? 'docusignAuth.logInModal.mappingNotConnected'
                : 'docusignAuth.logInModal.wrongAccount'
            )}
          </TypeRamp>

          <TypeRamp variant='bodyText'>
            {mapping?.docusign?.userName
              ? (
                <Spacer orientation='column'>
                  <span style={{ marginBottom: '0.5rem' }}>
                    {t(
                      !docusignAuth
                        ? 'docusignAuth.logInModal.logInAs'
                        : 'docusignAuth.logInModal.correct'
                    )}
                  </span>
                  <span>{mapping.docusign.userName}</span>
                  <span>{mapping.docusign.email}</span>
                  <span>{mapping.docusign.permissionProfileName}</span>
                </Spacer>
                )
              : (
                  t('docusignAuth.logInModal.logInToUseMapping')
                )}
            {mapping?.docusign?.userName && docusignAuth?.user && (
              <Spacer orientation='column' style={{ marginTop: '1rem' }}>
                <span style={{ marginBottom: '0.5rem' }}>
                  {t('docusignAuth.logInModal.incorrect')}
                </span>
                <span>{docusignAuth.user.userName}</span>
                <span>{docusignAuth.user.email}</span>
                <span>{docusignAuth.user.permissionProfileName}</span>
              </Spacer>
            )}
          </TypeRamp>
        </Spacer>

        <Banner type='warning' style={{ margin: '1rem 0' }}>
          <BannerContent
            style={{ margin: '0 .7rem' }}
            dangerouslySetInnerHTML={{
              __html: t(
                `docusignAuth.logInModal.${
                  smartsheetObject.type === 'sheet'
                    ? 'logoutWarningSheet'
                    : 'logoutWarningReport'
                }`
              )
            }}
          />
        </Banner>
      </ModalBody>
      <ModalFooter>
        <ModalFooterButton appearance='secondary' onClick={close}>
          {t('confirmationModal.cancel')}
        </ModalFooterButton>
        {!docusignAuth
          ? (
            <DocuSignAuthRetry closeModal={close} />
            )
          : (
            <ModalFooterButton onClick={handleLogout} isLoading={isLoading}>
              {t('wizard.screens.docusign.auth.button_logout')}
            </ModalFooterButton>
            )}
      </ModalFooter>
    </Modal>
  )
}

export default LogInModal
