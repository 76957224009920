const formatStaticOption = (label, t) => ({
  label: label || t('columnCard.noData'),
  value: -1,
  type: 'DOCUSIGN'
})

export const isStaticRecipient = (recipient) =>
  recipient.signingGroupUsers ||
  (recipient.name && recipient.email) ||
  (recipient.hostName && recipient.hostEmail)

// returns the current paired name and email options for initializing state
export const getPairedOptions = (pairing, options) => {
  // if user data has not been paired
  if (!pairing) {
    return {
      nameOption: null,
      emailOption: null
    }
  }
  // if user data comes from smartsheet contact column
  if (pairing.contactColumnId) {
    return {
      nameOption: options.find(
        (option) => option.value === pairing.contactColumnId
      ),
      emailOption: null
    }
  }
  // if user data comes from smartsheet name/email columns
  if (pairing.nameColumnId) {
    return {
      nameOption: options.find(
        (option) => option.value === pairing.nameColumnId
      ),
      emailOption: options.find(
        (option) => option.value === pairing.emailColumnId
      )
    }
  }
}

// In a report, the CONTACT_LIST columns of the underlying sheets
// all appear as MULTI_CONTACT_LIST columns, so we must include them to
// support contact list columns in reports.
export const isContactColumn = (opt) => {
  return opt
    ? opt.type === 'CONTACT_LIST' || opt.type === 'MULTI_CONTACT_LIST'
    : false
}

export const getStaticOptions = (recipient, t) => {
  if (recipient.name && recipient.email) {
    return {
      nameOption: formatStaticOption(recipient.name, t),
      emailOption: formatStaticOption(recipient.email, t)
    }
  }
  if (recipient.hostName && recipient.hostEmail) {
    return {
      nameOption: formatStaticOption(recipient.hostName, t),
      emailOption: formatStaticOption(recipient.hostEmail, t)
    }
  }
  return {
    nameOption: null,
    emailOption: null
  }
}

// Valid column types for recipient pairing are TEXT_NUMBER and CONTACT_LIST.
// This transforms the sheet columns into the data structure expected by the
// LodeStar Select element
export const getColumnOptions = (sheet) => {
  if (!sheet.columns) return
  return sheet.columns
    .filter((c) => c.type === 'TEXT_NUMBER' || isContactColumn(c))
    .map((c) => ({
      label: c.title,
      value: c.id || c.virtualId, // id if sheet, virtualId if report
      type: c.type
    }))
}

export const areAllRecipientsFilled = (flattenedRecipients, pairings) => {
  if (!pairings || !Object.values(pairings).length) return false

  let configuredCount = 0
  flattenedRecipients.forEach((recipient) => {
    if (pairings[recipient.recipientId]) configuredCount++
  })
  return configuredCount === flattenedRecipients.length
}
