// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editor {
  display: flex;
  width: 100%;
  height: 80%;
}

.editor .left-column {
  width: 30%;
}

.editor .right-column {
  width: 70%;
}

.editor .info-panel {
  padding-left: 1.3em;
  padding-right: 1.3em;
}

.info-panel h3 {
  margin-top: 0;
}
`, "",{"version":3,"sources":["webpack://./src/app/editor/Editor.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".editor {\n  display: flex;\n  width: 100%;\n  height: 80%;\n}\n\n.editor .left-column {\n  width: 30%;\n}\n\n.editor .right-column {\n  width: 70%;\n}\n\n.editor .info-panel {\n  padding-left: 1.3em;\n  padding-right: 1.3em;\n}\n\n.info-panel h3 {\n  margin-top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
