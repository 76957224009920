// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-header {
  display: flex;
  justify-content: space-between;
  height: 4.44rem;
}

/* Items on the Left side of the header */

.page-header .left-box {
  display: flex;
  align-items: center;
  margin: 0 1rem;
}

.page-header .left-box span {
  margin-left: 0.1rem;
  margin-right: 0.1rem;
}

.page-header .left-box .mapping-crumb-title {
  margin-top: 0.2rem;
  padding: 0 0.368rem;
}

.page-header .left-box .mapping-title-field {
  margin-left: 0.5rem;
}

.page-header .left-box .mapping-title-field input {
  width: 23rem;
  font-size: 1.1em;
}

.page-header .right-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1rem;
  column-gap: 0.25rem;
}

.page-header .right-box .button-create {
  margin-left: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/common/PageHeader.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,eAAe;AACjB;;AAEA,yCAAyC;;AAEzC;EACE,aAAa;EACb,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".page-header {\n  display: flex;\n  justify-content: space-between;\n  height: 4.44rem;\n}\n\n/* Items on the Left side of the header */\n\n.page-header .left-box {\n  display: flex;\n  align-items: center;\n  margin: 0 1rem;\n}\n\n.page-header .left-box span {\n  margin-left: 0.1rem;\n  margin-right: 0.1rem;\n}\n\n.page-header .left-box .mapping-crumb-title {\n  margin-top: 0.2rem;\n  padding: 0 0.368rem;\n}\n\n.page-header .left-box .mapping-title-field {\n  margin-left: 0.5rem;\n}\n\n.page-header .left-box .mapping-title-field input {\n  width: 23rem;\n  font-size: 1.1em;\n}\n\n.page-header .right-box {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin: 0 1rem;\n  column-gap: 0.25rem;\n}\n\n.page-header .right-box .button-create {\n  margin-left: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
