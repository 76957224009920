import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import isPlainObject from 'lodash/isPlainObject'
import omitBy from 'lodash/omitBy'
import pick from 'lodash/pick'

import {
  getMappingsList,
  listDocuSignTemplateDocuments,
  upsertMapping as upsertMappingDB
} from '../SmartfillApi'
import {
  getFieldsFromDocuments,
  getUpdatedDataSyncRefs
} from '../util/docusign'
import { isReadyToSave } from '../util/mapping'

export const initialState = {
  mappingList: null,
  currentMapping: null,
  isCurrentMappingChanged: null
}

export const fetchMappingList = createAsyncThunk(
  'mapping/fetchMappingList',
  async (smartsheetObject) => {
    const { data: mappingList } = await getMappingsList(smartsheetObject)

    return mappingList
  }
)

export const saveCurrentMapping = createAsyncThunk(
  'mapping/saveCurrentMapping',
  async (_, { getState, dispatch }) => {
    const state = getState()
    const currentMapping = selectCurrentMapping(state)

    if (!currentMapping) {
      throw new Error(
        "Can't update a mapping because a mapping is not being edited"
      )
    }

    if (!isReadyToSave(currentMapping)) {
      throw new Error('Current mapping is not ready to save')
    }

    // need to purge null properties (and temp) before persisting to db
    const mappingToSave = omitBy({ ...currentMapping, temp: null }, isNil)

    const { data: savedMapping } = await upsertMappingDB(mappingToSave)

    // update mapping list without refetching
    dispatch(upsertMappingList(savedMapping))

    return savedMapping
  }
)

export const fetchDocusignTemplateMetaList = createAsyncThunk(
  'docusign/fetchDocusignTemplateMetaList',
  async (_, { getState }) => {
    const currentMapping = selectCurrentMapping(getState())

    if (!currentMapping || !currentMapping.docusign) {
      throw new Error(
        "DocuSign template meta list can't be set unless current mapping is set and of type DocuSign."
      )
    }

    const { data: templateMetaList } = await listDocuSignTemplateDocuments(
      currentMapping.docusign.templateId,
      currentMapping.smartsheetObject
    )

    const templateMetaListFiltered = []

    templateMetaList.forEach((doc) => {
      const docFiltered = {}
      docFiltered.totalPageCount = doc.pages.length
      docFiltered.tabs = {}

      Object.entries(doc.tabs).forEach(([tabType, tabTypeList]) => {
        // Filter out all tab types lists with empty values.
        if (!tabTypeList) return

        // Return tab type list with only props needed in list items.
        docFiltered.tabs[tabType] = tabTypeList.map((tab) => {
          return pick(tab, [
            'tabId',
            'tabLabel',
            'pageNumber',
            'tabType',
            'width',
            'height',
            'xPosition',
            'yPosition'
          ])
        })
      })

      templateMetaListFiltered.push(docFiltered)
    })

    return templateMetaListFiltered
  }
)

const getUpdatedDataSync = (updatedMapping) => {
  if (!updatedMapping.docusign.templateMetaList) {
    console.error('no docusign templateMetaList found')
  }

  const fields = getFieldsFromDocuments(
    updatedMapping.docusign.templateMetaList
  )
  const updatedDataSyncRefs = getUpdatedDataSyncRefs(updatedMapping, fields)

  return updatedDataSyncRefs
}

const mappingSlice = createSlice({
  name: 'mapping',
  initialState,
  reducers: {
    upsertMappingList: (state, { payload: newMapping }) => {
      if (!state.mappingList) return
      if (!isPlainObject(newMapping)) return

      const filteredMappings = state.mappingList.filter(
        (mapping) => mapping.id !== newMapping.id
      )
      state.mappingList = [...filteredMappings, newMapping]
    },
    deleteMappingList: (state, { payload: newMappingId }) => {
      if (!state.mappingList) return

      state.mappingList = state.mappingList.filter(
        (mapping) => mapping.id !== newMappingId
      )
    },
    setCurrentMapping: (state, { payload: currentMapping }) => {
      state.currentMapping = currentMapping
      state.isCurrentMappingChanged = false
    },
    clearCurrentMapping: (state) => {
      state.currentMapping = null
      state.isCurrentMappingChanged = null
    },
    updateCurrentMapping: (state, { payload: mappingUpdate }) => {
      if (!state.currentMapping) {
        console.error(
          "can't update a mapping because a mapping is not being edited"
        )
        return
      }

      if (mappingUpdate.id) {
        console.error("can't update the ID of an existing mapping")
        return
      }

      // datasync refs need to be null if empty
      const { dataSyncReferences } = mappingUpdate.docusign || {}
      if (dataSyncReferences && isEmpty(dataSyncReferences)) {
        mappingUpdate.docusign.dataSyncReferences = null
      }

      mappingUpdate = omitBy(
        { ...state.currentMapping, ...mappingUpdate },
        isNil
      )

      // datasync refs need to be populated with associated fields from sheet
      if (mappingUpdate.docusign?.dataSyncReferences) {
        mappingUpdate.docusign = {
          ...mappingUpdate.docusign,
          dataSyncReferences: getUpdatedDataSync(mappingUpdate)
        }
      }

      state.isCurrentMappingChanged =
        state.isCurrentMappingChanged ||
        !isEqual(state.currentMapping, mappingUpdate)
      state.currentMapping = mappingUpdate
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchMappingList.fulfilled,
        (state, { payload: mappingList }) => {
          state.mappingList = mappingList
        }
      )
      .addCase(
        saveCurrentMapping.fulfilled,
        (state, { payload: savedMapping }) => {
          state.currentMapping = savedMapping
          state.isCurrentMappingChanged = false
        }
      )
      .addCase(
        fetchDocusignTemplateMetaList.fulfilled,
        (state, { payload: templateMetaList }) => {
          if (!state.currentMapping.docusign) return

          state.currentMapping.docusign.templateMetaList = templateMetaList
        }
      )
  }
})

export const selectMappingList = (state) => {
  return state.mapping.mappingList
}

export const selectCurrentMapping = (state) => {
  return state.mapping.currentMapping
}

export const selectIsCurrentMappingChanged = (state) => {
  return state.mapping.isCurrentMappingChanged
}

export const {
  updateCurrentMapping,
  upsertMappingList,
  deleteMappingList,
  setCurrentMapping,
  clearCurrentMapping
} = mappingSlice.actions

export default mappingSlice.reducer
