// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.job-status-item {
  width: 320px;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

.job-status-item:first-child {
  padding-top: 0;
}

.job-status-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.job-status-item .loader {
  height: 25em;
}

.job-status-item .job-status-heading {
  display: flex;
}

.job-status-item .job-name {
  font-weight: bold;
  margin-right: auto;
  padding-bottom: 1em;
}

.job-status-item .job-progress {
  width: 100%;
}

.job-status-item .status-message {
  margin-top: 0.2rem;
  text-align: center;
  font-weight: bold;
}

.job-status-item .job-errors {
  margin: 0.5rem 0 0 0;
  padding: 0 0 0 1.1rem;
  font-weight: bold;
  color: #d0011b;
}
`, "",{"version":3,"sources":["webpack://./src/app/batch-job-status/BatchJobStatusItem.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;EACnB,oBAAoB;EACpB,+CAA+C;AACjD;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;EACpB,qBAAqB;EACrB,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":[".job-status-item {\n  width: 320px;\n  margin-bottom: 1rem;\n  padding-bottom: 1rem;\n  border-bottom: 1px solid rgba(34, 36, 38, 0.15);\n}\n\n.job-status-item:first-child {\n  padding-top: 0;\n}\n\n.job-status-item:last-child {\n  margin-bottom: 0;\n  padding-bottom: 0;\n  border-bottom: none;\n}\n\n.job-status-item .loader {\n  height: 25em;\n}\n\n.job-status-item .job-status-heading {\n  display: flex;\n}\n\n.job-status-item .job-name {\n  font-weight: bold;\n  margin-right: auto;\n  padding-bottom: 1em;\n}\n\n.job-status-item .job-progress {\n  width: 100%;\n}\n\n.job-status-item .status-message {\n  margin-top: 0.2rem;\n  text-align: center;\n  font-weight: bold;\n}\n\n.job-status-item .job-errors {\n  margin: 0.5rem 0 0 0;\n  padding: 0 0 0 1.1rem;\n  font-weight: bold;\n  color: #d0011b;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
