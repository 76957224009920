// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.role-assigner {
  height: 100%;
}

.role-assigner .copy-container {
  height: 20%;
}

.role-assigner .grid-container {
  height: 80%;
}

.role-assigner .grid-body {
  display: grid;
  grid-template-columns: max-content 2rem 1fr 1fr;
  row-gap: 1rem;
  column-gap: 1rem;
  align-content: start;
  height: 100%;
  padding-right: 0.5rem;
  overflow-y: auto;
}

.role-assigner .grid-header {
  display: contents;
}

.role-assigner .grid-header div {
  /* Turn on sticky header again if floating Lodestar Select component gets fixed. */
  /* position: sticky;
  top: 0%;
  background: #ffffff; */
  font-weight: bold;
}

.role-assigner .grid-header .tooltip-icon {
  display: inline;
}

.role-assigner .grid-header .info-icon {
  vertical-align: middle;
}

.role-assigner .grid-content {
  display: contents;
}
`, "",{"version":3,"sources":["webpack://./src/app/wizard/role-assigner/RoleAssigner.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,+CAA+C;EAC/C,aAAa;EACb,gBAAgB;EAChB,oBAAoB;EACpB,YAAY;EACZ,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kFAAkF;EAClF;;wBAEsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".role-assigner {\n  height: 100%;\n}\n\n.role-assigner .copy-container {\n  height: 20%;\n}\n\n.role-assigner .grid-container {\n  height: 80%;\n}\n\n.role-assigner .grid-body {\n  display: grid;\n  grid-template-columns: max-content 2rem 1fr 1fr;\n  row-gap: 1rem;\n  column-gap: 1rem;\n  align-content: start;\n  height: 100%;\n  padding-right: 0.5rem;\n  overflow-y: auto;\n}\n\n.role-assigner .grid-header {\n  display: contents;\n}\n\n.role-assigner .grid-header div {\n  /* Turn on sticky header again if floating Lodestar Select component gets fixed. */\n  /* position: sticky;\n  top: 0%;\n  background: #ffffff; */\n  font-weight: bold;\n}\n\n.role-assigner .grid-header .tooltip-icon {\n  display: inline;\n}\n\n.role-assigner .grid-header .info-icon {\n  vertical-align: middle;\n}\n\n.role-assigner .grid-content {\n  display: contents;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
