import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import pick from 'lodash/pick'

import { getDocuSignAuthContext, listDocuSignTemplates } from '../SmartfillApi'

const initialState = {
  docusignAuth: null,
  docusignTemplateList: null
}

export const fetchDocusignAuth = createAsyncThunk(
  'docusign/fetchDocusignAuth',
  async (smartsheetObject) => {
    const { data } = await getDocuSignAuthContext(smartsheetObject)

    // Return only props and values needed.
    const docusignAuthFiltered = {
      account: pick(data.account, ['accountIdGuid', 'accountName']),
      user: pick(data.user, ['userName', 'email', 'permissionProfileName'])
    }

    return docusignAuthFiltered
  }
)

export const fetchDocusignTemplateList = createAsyncThunk(
  'docusign/fetchDocusignTemplateList',
  async (smartsheetObject) => {
    const { data: docusignTemplateList } =
      await listDocuSignTemplates(smartsheetObject)
    const filterRecipientProps = (list) => {
      if (!list) return []

      const recipientPropAllowList = [
        'recipientId',
        'recipientType',
        'roleName',
        'name',
        'email',
        'signingGroupUsers',
        'signerName',
        'hostName',
        'hostEmail'
      ]

      return list.map((recipient) => pick(recipient, recipientPropAllowList))
    }

    // Return only props and values needed.
    const docusignTemplateListFiltered = docusignTemplateList.map(
      (template) => {
        return {
          name: template.name,
          templateId: template.templateId,
          recipients: {
            inPersonSigners: filterRecipientProps(
              template.recipients.inPersonSigners
            ),
            signers: filterRecipientProps(template.recipients.signers),
            carbonCopies: filterRecipientProps(
              template.recipients.carbonCopies
            ),
            certifiedDeliveries: filterRecipientProps(
              template.recipients.certifiedDeliveries
            )
          }
        }
      }
    )

    return docusignTemplateListFiltered
  }
)

const docusignSlice = createSlice({
  name: 'docusign',
  initialState,
  reducers: {
    clearDocusignAuth: (state) => {
      state.docusignAuth = null
    },
    clearDocusignTemplateList: (state) => {
      state.docusignTemplateList = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchDocusignAuth.fulfilled,
        (state, { payload: docusignAuth }) => {
          state.docusignAuth = docusignAuth
        }
      )
      .addCase(
        fetchDocusignTemplateList.fulfilled,
        (state, { payload: docusignTemplateList }) => {
          state.docusignTemplateList = docusignTemplateList
        }
      )
  }
})

export const selectDocusignAuth = (state) => {
  return state.docusign.docusignAuth
}

export const selectDocusignTemplateList = (state) => {
  return state.docusign.docusignTemplateList
}

export const selectTemplateById = (id) => (state) => {
  const docusignTemplateList = selectDocusignTemplateList(state)

  if (docusignTemplateList) {
    return docusignTemplateList.find((template) => template.templateId === id)
  } else {
    return null
  }
}

export const { clearDocusignAuth, clearDocusignTemplateList } =
  docusignSlice.actions

export default docusignSlice.reducer
