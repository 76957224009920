import { ClickAwayListener } from '@material-ui/core'
import { Button, Flyout, useFlyout } from '@smartsheet/lodestar-core'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { generate, generateDocuSignEnvelopes } from '../common/SmartfillApi'
import {
  selectSheet,
  selectSheetRowIds,
  selectSmartsheetObject
} from '../common/store/app'
import {
  BATCH_JOB_STATUS_TEMPLATE,
  BATCH_JOB_TYPE,
  selectDownloadCompleteBatchJobStatusList,
  updateBatchJobStatus
} from '../common/store/batchJobStatus'
import { setError } from '../common/store/error'
import { getLimitExceeded, msgKey } from '../common/util/confModal'
import { rowLimit } from '../common/util/constants'
import {
  isDocuSignMapping,
  isNativeMapping,
  isReadyToSave
} from '../common/util/mapping'
import { userCanAttachToRows } from '../common/util/permissions'

import './GenerateControl.css'

const GenerateControl = ({ mapping, openModal, isAuthedAsCreator }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const sheet = useSelector(selectSheet)
  const smartsheetObject = useSelector(selectSmartsheetObject)
  const rowIds = useSelector(selectSheetRowIds)
  const rowCount = sheet.rows.length
  const downloadCompleteBatchJobStatusList = useSelector(
    selectDownloadCompleteBatchJobStatusList
  )

  const [generating, setGenerating] = useState(false)
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [fileDownload, setFileDownload] = useState({
    name: null,
    path: null
  })
  const [downloadList, setDownloadList] = useState([])

  const fileDownloadLinkRef = useRef()

  const { targetProps, flyoutProps } = useFlyout({
    isOpen: menuIsOpen,
    onCloseRequested: () => setMenuIsOpen(false)
  })

  useEffect(() => {
    // Force file download by clicking hidden link if data exists.
    if (fileDownload.name && fileDownload.path) {
      fileDownloadLinkRef.current.click()
    }
  }, [fileDownload])

  useEffect(() => {
    downloadList.forEach((batchJobStatusId) => {
      const batchJobStatus = downloadCompleteBatchJobStatusList.find(
        (batchJobStatus) => batchJobStatus.id === batchJobStatusId
      )

      if (batchJobStatus) {
        // Trigger a download of the file.
        setFileDownload({
          name: batchJobStatus.fileName,
          path: process.env.BASE_API_URL + batchJobStatus.filePath
        })

        // Remove batch job status id from download list so won't be checked again.
        setDownloadList(downloadList.filter((id) => id !== batchJobStatusId))
      }
    })
  }, [downloadCompleteBatchJobStatusList])

  const checkForRateLimitError = (error) => {
    // HACK: If rate-limit error modify error object so error handling code reports correct messaging.
    if (error.response.status === 429 && error.response.data === '') {
      error.response.data = { code: 429 }
    }

    return error
  }

  const downloadPdf = async () => {
    let resp = null

    try {
      resp = await generate(
        mapping.id,
        smartsheetObject,
        BATCH_JOB_TYPE.downloads,
        rowIds
      )
    } catch (error) {
      dispatch(setError(checkForRateLimitError(error)))
    }

    if (!resp) return

    if (resp.data.jobId) {
      dispatch(
        updateBatchJobStatus({
          batchJobStatus: {
            id: resp.data.jobId,
            mappingId: mapping.id,
            mappingName: mapping.name,
            sheetName: resp.data.sheetName,
            jobType: BATCH_JOB_TYPE.downloads,
            ...BATCH_JOB_STATUS_TEMPLATE
          }
        })
      )
      setDownloadList([...downloadList, resp.data.jobId])
    }
  }

  const attachPdf = async () => {
    let resp = null

    try {
      resp = await generate(
        mapping.id,
        smartsheetObject,
        BATCH_JOB_TYPE.attachments,
        rowIds
      )
    } catch (error) {
      dispatch(setError(checkForRateLimitError(error)))
    }

    if (!resp) return

    if (resp.data.jobId) {
      dispatch(
        updateBatchJobStatus({
          batchJobStatus: {
            id: resp.data.jobId,
            mappingId: mapping.id,
            mappingName: mapping.name,
            sheetName: resp.data.sheetName,
            jobType: BATCH_JOB_TYPE.attachments,
            ...BATCH_JOB_STATUS_TEMPLATE
          }
        })
      )
    }
  }

  const buildDocuSign = async (outputOption) => {
    const isDraft = outputOption === BATCH_JOB_TYPE.docuSignEnvelopeDrafts
    let resp = null

    try {
      resp = await generateDocuSignEnvelopes(
        mapping.id,
        smartsheetObject,
        rowIds,
        isDraft
      )
    } catch (error) {
      dispatch(setError(checkForRateLimitError(error)))
    }

    if (!resp) return

    if (resp.data.jobId) {
      dispatch(
        updateBatchJobStatus({
          batchJobStatus: {
            id: resp.data.jobId,
            mappingId: mapping.id,
            mappingName: mapping.name,
            jobType: isDraft
              ? BATCH_JOB_TYPE.docuSignEnvelopeDrafts
              : BATCH_JOB_TYPE.docuSignEnvelopes,
            ...BATCH_JOB_STATUS_TEMPLATE
          }
        })
      )
    }
  }

  const handleGenerate = (outputOption) => {
    setGenerating(true)

    if (outputOption === BATCH_JOB_TYPE.downloads) {
      downloadPdf()
    } else if (outputOption === BATCH_JOB_TYPE.attachments) {
      attachPdf()
    } else {
      buildDocuSign(outputOption)
    }

    setGenerating(false)
  }

  const canGenerate = () => {
    if (generating) return false
    if (!rowCount || rowCount > rowLimit) return false
    if (!isReadyToSave(mapping)) return false
    return true
  }

  const canGenerateRowAttachments = () => {
    if (!userCanAttachToRows(sheet)) return false
    if (!canGenerate()) return false
    return !(mapping.smartsheetObject.type === 'report')
  }

  const onGenerate = (outputOption) => {
    setMenuIsOpen(false)

    // Check first if exceeded row count limit.
    if (getLimitExceeded(outputOption, rowCount)) {
      // Run limit exceeded confirm modal.
      openModal({
        message: msgKey[outputOption],
        data: { rowCount },
        buttonHandlers: {
          onCancel: () => () => {},
          onConfirm: () => handleGenerate(outputOption)
        }
      })
    } else {
      handleGenerate(outputOption)
    }
  }

  const getMenuOptions = () => {
    const pluralSuffix = rowCount === 1 ? '' : '_plural'

    if (isNativeMapping(mapping)) {
      return (
        <>
          <div className='generate-option download'>
            <Button
              className='option-button'
              data-dd-action-name='smar:gen.con.download.btn'
              onClick={() => onGenerate(BATCH_JOB_TYPE.downloads)}
            >
              {t(`generateControl.download${pluralSuffix}`, {
                count: rowCount
              })}
            </Button>
          </div>
          <div className='generate-option row-attachments'>
            <Button
              className='option-button'
              isDisabled={!canGenerateRowAttachments()}
              data-dd-action-name='smar:gen.con.attach.btn'
              onClick={() => onGenerate(BATCH_JOB_TYPE.attachments)}
            >
              {t(`generateControl.attach${pluralSuffix}`, { count: rowCount })}
            </Button>
          </div>
        </>
      )
    }
    if (isDocuSignMapping(mapping)) {
      const optionsDisabled = !userCanAttachToRows(sheet)
      return (
        <>
          <div className='generate-option docusign-envelopes'>
            <Button
              className='option-button'
              data-dd-action-name='smar:gen.con.docusign.envelope.btn'
              isDisabled={optionsDisabled}
              onClick={() => onGenerate(BATCH_JOB_TYPE.docuSignEnvelopes)}
            >
              {t(`generateControl.docusign.envelope${pluralSuffix}`, {
                count: rowCount
              })}
            </Button>
          </div>
          <div className='generate-option docusign-envelope-drafts'>
            <Button
              className='option-button'
              data-dd-action-name='smar:gen.con.docusign.draft.btn'
              isDisabled={optionsDisabled}
              onClick={() => {
                onGenerate(BATCH_JOB_TYPE.docuSignEnvelopeDrafts)
              }}
            >
              {t(`generateControl.docusign.draft${pluralSuffix}`, {
                count: rowCount
              })}
            </Button>
          </div>
        </>
      )
    }
    return ''
  }

  return (
    <div className='generate-control' onMouseLeave={() => setMenuIsOpen(false)}>
      <Button
        {...targetProps}
        data-dd-action-name='smar:gen.con.dropdown.btn'
        onClick={() => setMenuIsOpen(true)}
        className='generate-button'
        data-testid='generate-button'
        isDisabled={
          !canGenerate() || (isDocuSignMapping(mapping) && !isAuthedAsCreator)
        }
        isLoading={generating}
        aria-haspopup
      >
        {t('generateControl.generate')}
      </Button>
      <Flyout {...flyoutProps} placement='bottom-start'>
        <ClickAwayListener
          onClickAway={() => {
            setMenuIsOpen(false)
          }}
        >
          <div className='generate-menu'>{getMenuOptions()}</div>
        </ClickAwayListener>
      </Flyout>
      {/* Hidden link button to trigger file download */}
      <a
        href={fileDownload.path}
        target='_blank'
        rel='noopener noreferrer'
        download={fileDownload.name}
        ref={fileDownloadLinkRef}
        style={{ display: 'none' }}
      >
        Download
      </a>
    </div>
  )
}

GenerateControl.propTypes = {
  mapping: PropTypes.object.isRequired,
  isAuthedAsCreator: PropTypes.bool
}

export default GenerateControl
