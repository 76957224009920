// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmation-modal .modal-body {
  padding-top: 2.2rem;
}

.confirmation-modal .modal-body div:first-child {
  flex-direction: row;
  align-items: center;
  overflow: visible;
}

.confirmation-modal .modal-body div:last-child {
  background-color: #ffffff;
}

.confirmation-modal .modal-body .icon {
  margin: 0 0.5rem auto 0;
  color: #d0011b;
}

.confirmation-modal .modal-body .message {
  font-weight: normal;
}

.confirmation-modal .modal-footer {
  padding-top: 1.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/common/useConfirmationModal.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;EACvB,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".confirmation-modal .modal-body {\n  padding-top: 2.2rem;\n}\n\n.confirmation-modal .modal-body div:first-child {\n  flex-direction: row;\n  align-items: center;\n  overflow: visible;\n}\n\n.confirmation-modal .modal-body div:last-child {\n  background-color: #ffffff;\n}\n\n.confirmation-modal .modal-body .icon {\n  margin: 0 0.5rem auto 0;\n  color: #d0011b;\n}\n\n.confirmation-modal .modal-body .message {\n  font-weight: normal;\n}\n\n.confirmation-modal .modal-footer {\n  padding-top: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
